@import "../../../../../../styles/index";

.actions_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  .icon_color {
    color: $primary2;
  }
}

.add-image-btn__wrapper {
  button {
    background-color: $section-button !important;
  }
}
