@import "../../../../../styles/index";

.sold_table {
  margin-bottom: 20px;
  width: 100%;
  overflow-x: auto;

  .table {
    width: 100%;
    background-color: $white1;
    border-radius: 30px;
    border-spacing: 0px !important;
    border: 1px solid #daebee;
    min-width: 650px;

    thead {
      background-color: $primary-pale;
      color: $white1;

      th {
        font-size: 18px;
        font-weight: 600;
        padding: 15px;
        line-height: 1.1;
      }

      th:not(:last-child) {
        border-right: 1px solid #daebee;
      }
    }

    tbody {
      tr {
        text-align: center;
        height: 70px;

        td {
          font-size: 14px;
          border-bottom: 1px solid #daebee;
          padding: 0 15px;
        }

        td:first-child {
          padding: 0px;

          img {
            width: 100%;
            height: 100px;
            object-fit: cover;
            vertical-align: top;
          }
        }

        .price_column {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: inherit;

          b {
            font-size: inherit;
          }

          img {
            width: 12px;
          }
        }

        .flex_column {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          svg {
            cursor: pointer;
            color: #c70000;
          }
        }
      }
    }

    tr:last-child > td {
      border-bottom: none;
    }
  }
}

.legend {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  margin-bottom: 60px;

  &__text {
    font-size: 14px;

    img {
      width: 12px;
    }
  }
}

.arrow_img {
  transform: rotateX(180deg);
}
