@import "../../../../styles/index";

.agent_characteristics {
  padding: 50px 70px;
  display: flex;
  flex-direction: column;

  @media (max-width: 960px) {
    padding: 50px 20px;
  }

  button {
    align-self: flex-start;
    background: $section-button;
  }
}
