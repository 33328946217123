@import "../../../../styles/index.scss";

.admin_search_bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;

  &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;

    &__btn {
      width: 50%;
      height: 50px;
      font-weight: $font-weight-bold;
      font-size: 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 2px solid;

      &.houseplus {
        border-color: #078ea6;
        color: #078ea6;

        &.active {
          background-color: #078ea6;
          color: #ffffff;
        }
      }

      &.mondored {
        border-color: #b02832;
        color: #b02832;

        &.active {
          background-color: #b02832;
          color: #ffffff;
        }
      }

      &.bakeca {
        border-color: #567a1f;
        color: #567a1f;

        &.active {
          background-color: #567a1f;
          color: #ffffff;
        }
      }

      &.type_search {
        border-color: #034349;
        color: #034349;

        &.active {
          background-color: #034349;
          color: #ffffff;
        }
      }
    }
  }

  .platforms {
    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .admin_search_bar__filter__btn {
        width: 100%;
      }
    }
  }

  &__bar {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    &__input {
      width: 80%;
      margin-bottom: 0 !important;

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    &__btn {
      width: 20%;
      height: 60px;
      min-width: 100px;

      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
}
