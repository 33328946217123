@import "../../../../styles/index";

.buy_cta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: $white1;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100%;
  padding: 40px;
  min-height: 200px;

  &__logo {
    width: 220px;
  }

  &__text {
    margin-bottom: 20px;
    line-height: 1.2;
    max-width: 800px;
    text-align: center;
  }
}
