@import "./modules/colors.scss";
@import "./modules/mixins.scss";
@import "./modules/variables.scss";
@import "./modules/reset.scss";

* {
  box-sizing: border-box;
  font-family: $font-family;
  font-size: $base-font-size;
  font-style: normal;
  font-weight: $font-weight-normal;
  letter-spacing: $base-letter-spacing;
  line-height: $base-line-height;
  margin: 0;
  padding: 0;
}

sup {
  font-size: 10px;
}

.chart_menu_box {
  background-color: white;
  height: auto;
  width: 230px;
  padding: 20px 20px;

  .consigliato_box {
    background-color: #bc99e2;
    border-radius: 6px;
    margin-top: 3px;
    height: 40px;
    padding: 10px 12px;
    font-size: 12px;
    color: #ffffff;
  }
  .title_head {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
  }

  .val_head {
    font-size: 20px;
    // margin-bottom: 9px;
  }

  .val_serie_box {
    margin-top: 10px;
    border-left-width: 3px;
    border-left-style: solid;
    padding-left: 10px;
  }

  .msei {
    border-left-color: $proba-chart-selected;
  }

  .mdodici {
    border-left-color: $proba-chart-real;
  }

  .within__btw {
    background-color: white;
  }
}

.compared-stop-color {
  stop-color: $compared-stop-color;
}

h2,
h3,
h4,
h5 {
  color: $primary3;
  font-weight: $font-weight-bold;
  letter-spacing: 0.35px;
}

h1,
.h1 {
  font-size: 34px;
  font-weight: $font-weight-bolder;
  line-height: 1.5;
}

h2,
.h2 {
  font-size: 28px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
}

b,
strong {
  font-weight: $font-weight-bold;
}

button {
  cursor: pointer;
  outline: none;
}

span,
p,
b,
strong,
div {
  color: $primary3;
}

.eye-icon {
  width: 20px;
  height: 13px;
}

.MuiTooltip-popper {
  * {
    background-color: #ededed !important;
    color: $primary3 !important;
    font-size: 11px !important;
    font-weight: 500;
  }
}

.checked__btn {
  position: relative;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  padding: 0;

  &::after {
    content: "\2212";
    display: flex;
    display: -webkit-flex;
    position: absolute;
    top: -3px;
    left: -3px;
    width: 22px;
    height: 22px;
    align-items: center;
    -webkit-align-items: center;
    background-color: #fbe5e8;
    border: 2px solid $red;
    border-radius: 50%;
    color: $red;
    font-size: 24px;
    justify-content: center;
    -webkit-justify-content: center;
  }
}

.empty-table {
  text-align: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  margin: 0 auto;
  max-width: 520px;
  padding: 50px 0;

  @media (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .create-btn__wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top: 20px;

    .create-btn__focus-icon {
      position: absolute;
      right: 30%;
      bottom: -25px;
      width: 40px;
    }
  }
}

.poi-info-window {
  padding: 10px;

  .title {
    color: $primary3;
    font-size: 16px;
    font-weight: 600;
  }

  .title,
  .address {
    margin-bottom: 5px;

    * {
      color: $primary3;
    }
  }

  .view-link * {
    font-size: 14px;
    font-weight: 600;
  }
}

@media print {
  @page {
    margin: 0;
    size: A4 portrait;
  }
  .page-break {
    page-break-after: always;
  }
}
@media print {
  html,
  body,
  .container {
    height: 100% !important;
    width: 100% !important;
    overflow: visible;
    display: block;
  }
}

.slick-slide {
  margin: 0 10px;

  img {
    border-radius: 10px !important;
    height: 33vh;
    width: 100%;
    object-fit: cover;
  }
}

.slick-list {
  margin: 0 -10px;
}

.muitable {
  .MuiTableCell-root {
    padding: 16px 12px;
  }
}
