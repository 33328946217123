@import "../../../../styles/index.scss";

.user_price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  padding: 50px;

  &__header {
    margin-bottom: 10px;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 485px;
    height: 175px;
    // gap: 20px;
    padding: 20px 20px 20px 40px;
    border-radius: 30px;
    background-color: $white;
    position: relative;

    &__number {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      .value {
        font-weight: 700;
        font-size: 36px;
      }

      .probability {
        font-size: 20px;
        text-align: center;
        // width: 240px;

        b {
          font-size: inherit;
        }
      }
    }

    &__probability {
      position: relative;
      height: 100%;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
    }
  }

  &__text {
    text-align: justify;
    font-size: 15px;
    font-weight: 500;
    color: $primary-pale2;
    line-height: 1.3;
    flex: 1;
    width: 548px;
  }
}
