@import "../../../../styles";

.chart__wrapper {
    width: 100%;
    background-color: $white1;
    border-radius: 30px;
    padding: 35px 30px 20px;
    min-height: 450px;
    height: 100%;
    position: relative;

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
        font-size: 22px;
    }
  }