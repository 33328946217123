@import "../../../../styles/index";

.second_page {
  padding: 40px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  gap: 30px;
  // height: calc(100% - 75px);

  // h5 {
  //   margin-bottom: 15px;
  // }

  &__characteristichs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(4, 1fr);
    -webkit-grid-gap: 20px;
  }

  .characteristichs__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    -webkit-display: flex;
    -webkit-flex-direction: column;
    -webkit-gap: 20px;
    border: 3px solid $primary;
    border-radius: 10px;
    padding: 20px;
  }

  .characteristichs__title {
    font-size: 20px;
    text-transform: uppercase;
    color: $primary2;
    display: flex;
    align-items: center;
    gap: 10px;

    b {
      font-size: 18px;
      text-transform: uppercase;
      color: $primary2;
    }

    svg {
      width: 20px;
      height: 100%;
    }
  }

  .second_page__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    grid-auto-rows: min-content;
  }

  .characteristichs__structure {
    grid-column: 1/5;

    .characteristichs__column {
      @media (min-width: 800px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
  .characteristichs__external {
    grid-column: 1/3;

    svg {
      width: 22px;
    }

    .second_page__grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  .characteristichs__surfaces {
    grid-column: 3/5;

    .total_surface {
      color: $primary2;
      font-size: 16px;
      font-weight: 700;
      grid-column: 1/3;
      border: 2px solid;
      padding: 7px;
      border-radius: 4px;
      width: fit-content;

      b {
        font-size: 20px;
        color: $primary-pale;
        font-weight: 700;
        font-style: italic;
      }
    }

    svg {
      width: 25px;
    }

    .second_page__grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  .property_card {
    &_header {
      color: $primary2;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    &_content {
      color: $primary-pale;
      font-size: 12px;
      font-weight: 700;
    }
  }

  &__surface {
    background-color: $white1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__location {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;

    &_details {
      h3 {
        margin-bottom: 15px;
      }
    }

    .map {
      width: 100%;
      height: 240px;
      object-fit: cover;
      border-radius: 10px;
    }

    .description,
    .address,
    .area {
      margin-bottom: 20px;
    }

    .description {
      font-size: 15px;
    }

    .address {
      &_title {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
          height: 100%;
          width: 16px;
        }
      }

      p {
        font-size: 15px;
        margin-top: 10px;
        margin-left: 20px;
      }
    }
  }
}
