@import "../../../../styles/index";

.agent_inputs {
  padding: 35px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header__text {
    text-align: center;
    margin-bottom: 10px;
  }

  .secondary__text {
    font-size: 15px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: center;
    margin-bottom: 20px;
  }

  &__sold_properties {
    .table_container {
      .table {
        width: 100%;
        background-color: $white1;
        border-spacing: 0px !important;
        border-radius: 10px;
        min-width: 650px;
        margin-bottom: 10px;

        thead {
          background-color: $primary-pale;
          color: $white1;

          th {
            font-size: 13px;
            font-weight: 500;
            padding: 8.5px 10px;
            line-height: 1.1;
          }

          th:not(:last-child) {
            border-right: 1px solid #daebee;
          }
        }

        tbody {
          tr {
            text-align: center;
            height: 55px;

            td {
              font-size: 13px;
              border-bottom: 1px solid #daebee;
              padding: 0px 15px;
            }

            .price_column {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: inherit;

              b {
                font-size: inherit;
              }

              img {
                width: 9px;
              }
            }
          }
        }

        tr:last-child > td {
          border-bottom: none;
        }
      }

      .legend {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 20px;
        margin-bottom: 10px;

        &__text {
          font-size: 12px;

          img {
            width: 10px;
          }

          sup {
            font-size: 8px;
          }
        }
      }
    }
  }

  &__characteristics {
    &__cards {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;

      &__item {
        padding: 10px 10px;
        border-radius: 30px;
        font-size: 14px;
        font-weight: 600;
      }

      .positivo {
        background-color: #d2ffd5;
        color: #7b9b79;
      }

      .negativo {
        background-color: #ffd1d2;
        color: #927677;
      }

      .neutro {
        background-color: #dcdbdc;
        color: #939293;
      }
    }
  }

  &__pricing_simulator {
    .pricing_simulator__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      &__options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        &__list {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          &__item {
            background-color: $white1;
            padding: 10px;
            width: 226px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .title {
              font-size: 16px;
            }

            .content {
              font-weight: 500;
            }
          }
        }
      }

      &__cards {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        &__container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }

        .medium_card {
          background-color: $prob-sale-bg !important;
        }

        .best__selling__card {
          padding: 20px 25px;
          background-color: $white1;
          border-radius: 10px;
          width: 226px;

          .first__row {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            margin-bottom: 10px;

            .icon__wrapper {
              margin-right: 10px;
              padding: 4px;
              border-radius: 4px;
              width: 20px;
              height: 26.5px;
            }

            .icon_two_months {
              color: $best-selling-card1;
            }

            .icon_six_months {
              color: $best-selling-card2;
            }

            .icon_twelve_months {
              color: $best-selling-card3;
            }

            .first__row__text {
              font-size: 12px;
              font-weight: $font-weight-middle;
              color: $gray;
            }
          }

          .second_row_test {
            color: #9a9a9a;
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 5px;
          }

          .second_row_price {
            font-size: 14px;
          }

          .period {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: $font-weight-middle;
            color: $gray;
          }

          .price {
            font-size: 24px;
            font-weight: $font-weight-middle;

            span {
              font-size: 24px;
              font-weight: inherit;
              color: inherit;
            }
          }
        }

        .percent_box_positive {
          margin-left: 20px;
          padding: 7px 10px;
          background-color: #dbf6e5;
          border-radius: 30px;
          font-size: 13px;
          color: #628168;
        }

        .percent_box_negative {
          margin-left: 20px;
          padding: 7px 10px;
          background-color: #f6dbdb;
          border-radius: 30px;
          font-size: 13px;
          color: #9c3b3b;
        }
      }
    }
  }
}

.arrow_img {
  transform: rotateX(180deg);
}
