@import "../../../../styles/index.scss";

.radio-btn {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  // border: 1px solid $primary;
  height: 50px;
  min-width: 187px;
  width: auto;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  border-radius: 5px;
  background-color: $radio-btn-bg;
  transition: outline 0.05s ease;

  &:hover {
    outline: 1.5px solid $primary;
  }

  &:focus-visible {
    outline: 1.5px solid $primary;
  }

  span {
    display: flex;
    display: -webkit-flex;
    word-wrap: break-word;
    font-size: 18px;
    font-weight: 500;
    color: $radio-btn-text;
  }

  input {
    display: none;
  }
}

.radio-btn__checked {
  background-color: $primary;
  border: none;

  span {
    color: $white;
  }
}
