@import "../../../styles";

.chart__wrapper {
  width: 100%;
  background-color: $white1;
  border-radius: 10px;
  padding: 35px 30px 20px;
  min-height: 450px;
  height: 100%;
}

.chart__source {
  margin-top: 15px;
  font-size: 14px;
  text-align: end;

  @media (max-width: 1000px) {
    position: relative !important;
    right: auto !important;
    bottom: auto !important;
  }
}

.chart__container {
  @media (max-width: 750px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
