@import "../../../../styles";

.area__services__wrapper {
  padding: 0px 70px 50px;

  @media (max-width: 840px) {
    padding: 0px 20px 50px;
  }

  .header__text {
    margin-bottom: 40px;

    p {
      font-size: 36px;
      font-weight: $font-weight-middle;
    }
  }

  // .secondary__text {
  //   text-align: center;
  //   font-size: 20px;
  //   font-weight: $font-weight-middle;
  //   color: $primary-pale2;
  //   margin-bottom: 60px;
  // }

  .area__services__main {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(18, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(9, 1fr);
    -webkit-grid-template-rows: repeat(18, 1fr);
    -webkit-grid-column-gap: 30px;
    -webkit-grid-row-gap: 15px;

    @media (max-width: 1000px) {
      display: block;
    }

    .inflation_change {
      grid-column: 1 / 7;
      grid-row: 1 / 10;
    }

    .inflation {
      grid-column: 7 / 10;
      grid-row: 1 / 3;
    }

    .avg_earnings {
      grid-column: 7 / 10;
      grid-row: 3 / 10;
    }

    .bce {
      grid-column: 1 / 4;
      grid-row: 10 / 12;
    }

    .employment {
      grid-column: 1 / 4;
      grid-row: 12 / 19;
    }

    .bce_change {
      grid-column: 4 / 10;
      grid-row: 10 / 19;
    }
  }

  .general__card {
    background: $white1;
    border-radius: 30px;
    height: auto;

    &_container {
      position: relative;
      height: 100%;

      .chart_info {
        position: absolute;
        top: 76px;
        padding: 0px 30px;
        font-size: 16px;
        line-height: 18px;

        @media (max-width: 1000px) {
          font-size: 12px;
        }

        b {
          font-size: 17px;

          @media (max-width: 1000px) {
            font-size: 13px;
          }
        }
      }
    }

    @media (max-width: 1000px) {
      &:not(:last-child) {
        margin: 20px 0;
      }
      &:last-child {
        margin: 20px 0 0;
      }
    }
  }
}
