@import "../../../../styles/index.scss";

.admin_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  // padding: 0 30px;
  width: 100%;

  &__logo {
    width: 220px;
    height: 42px;

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  &__logo_small {
    width: 60px;

    img {
      width: 100%;
    }

    @media (min-width: 701px) {
      display: none;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &__link {
      @media (max-width: 500px) {
        display: none;
      }
    }

    &__link_mobile {
      position: relative;

      @media (min-width: 501px) {
        display: none;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        color: #078ea6;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  .logout {
    button,
    p {
      color: #fd7072 !important;
    }
  }
}
