@import "../../../../../../styles/index.scss";

.area__services__action {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  margin-top: 20px;

  button {
    background: $section-button !important;
  }
}

.area__services__action-mobile {
  justify-content: center;
  -webkit-justify-content: center;

  button {
    background: $section-button !important;
  }
}

.corner-background {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  border: 40px solid transparent;
  border-top: 40px solid $section-button;
  border-right: 40px solid $section-button;
}

.corner-value {
  position: absolute;
  font-weight: 400;
  color: white;
  z-index: 2;
  cursor: pointer;
  right: 15px;
  top: 15px;
}

.map-actions {
  position: absolute;
  margin: 20px;
  width: 82%;
  top: 0;
  left: 0;
  z-index: 3;

  .buttons {
    position: absolute;
    right: -60px;
    top: 4.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

    @media (max-width: 940px) {
      right: -51px;
    }

    @media (max-width: 841px) {
      display: none;
    }

    &_prev,
    &_next {
      width: 50px;
      height: 26px;
      background-color: $section-button;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: 940px) {
        width: 42.5px;
      }
    }

    &_prev {
      border-bottom-right-radius: 15px;
      box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
    }
    &_next {
      border-top-right-radius: 15px;
      border-bottom: 0.5px solid $white;
      box-shadow: 0px -4px 4px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .map-label {
    display: inline-flex !important;
    width: fit-content !important;
    align-items: center;
    background: white;
    padding: 5px 20px 5px 15px;
    margin: 5px 0px;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    opacity: 0.5;

    img {
      height: 40px;
      margin-right: 15px;
      width: 40px;
      object-fit: fill;
    }
  }

  .map-label__active {
    opacity: 1;
  }

  .map-label__stroke {
    outline: 2px solid $primary3;
  }
}

.distance-filter {
  display: flex;
  display: -webkit-flex;
  gap: 10px;
  position: absolute;
  margin: 20px;
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  .filter-item {
    cursor: pointer;
    padding: 15px 40px;
    border-radius: 10px;
    border: 1px solid $section-button;
    background-color: white;
  }

  .active-filter {
    background-color: $section-button;
    color: white;
  }
}

.map-modal__content {
  width: 100%;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;

  .home {
    width: 40px;
    color: $primary;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .pins {
    width: 40px;
    height: 40px;
    color: $compared-icon-color;

    svg {
      width: 40px;
      height: 40px;
    }
  }
  .map__point-active {
    opacity: 1;
  }
}
