@import "../../../../../styles/index";

.selects_list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    &__item {
      padding: 15px 10px;
      border-radius: 30px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &__btn {
        font-size: 14px;
        font-weight: 700;
      }

      &__title {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .positivo {
      background-color: #d2ffd5;

      .selects_list__item__title,
      .selects_list__item__btn {
        color: #7b9b79;
      }
    }

    .negativo {
      background-color: #ffd1d2;

      .selects_list__item__title,
      .selects_list__item__btn {
        color: #927677;
      }
    }

    .neutro {
      background-color: #dcdbdc;

      .selects_list__item__title,
      .selects_list__item__btn {
        color: #939293;
      }
    }
  }
}
