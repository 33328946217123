@import "../../../../styles/index.scss";

.container__inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-display: grid;
  -webkit-grid-template-columns: repeat(2, 1fr);
  width: 100%;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background: $auth-gradient;

  .reset-pwd__container {
    display: flex;
    display: -webkit-flex;
    height: 100%;
    align-items: center;
    -webkit-align-items: center;
    color: $primary3;
    flex-direction: column;
    -webkit-flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;

    .pwd-saved {
      width: 100%;
      max-width: 500px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      flex-direction: column;
      -webkit-flex-direction: column;

      .title {
        text-align: center;
        color: $primary;
        margin-bottom: 20px;
      }

      .text {
        font-size: 20px;
        margin-bottom: 40px;
        font-weight: 600;
      }

      & > button {
        max-width: 375px;
      }
    }

    .reset-pwd-form__wrapper {
      width: 100%;
      max-width: 375px;
      transform: translateY(-25%);

      h1 {
        margin-bottom: 40px;
        line-height: 1.3;
        max-width: 310px;
      }

      & > p {
        margin-bottom: 35px;
        font-size: 20px;
        font-weight: 500;
      }

      .reset-pwd__form {
        display: flex;
        display: -webkit-flex;
        width: 100%;
        flex-direction: column;
        -webkit-flex-direction: column;

        & > div {
          margin-bottom: 30px;
        }
      }
    }
    .reset-pwd-form__wrapper-mobile {
      padding: 0 20px;
      transform: none;
    }
  }
}

.container__inner-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  -webkit-display: grid;
  -webkit-grid-template-columns: repeat(1, 1fr);
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.right_div {
  height: 100%;
  background-repeat: repeat-y;
  background-size: contain;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  justify-content: center;
  -webkit-justify-content: center;
  padding-bottom: 20px;

  .partner {
    margin-left: 30%;
    text-align: center;
  }

  .trust_copy {
    color: white;
    margin-left: 35%;
    margin-top: 24px;
    text-align: center;
  }
}
