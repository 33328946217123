// #root {
//   margin-top: 25px;
//   margin-bottom: 25px;
// }
@import "../../../styles/index.scss";

.cont {
  margin: 25px;
}

.tooltipStyleChange {
  color: #006080;
}

/**/

.tooltip {
  position: relative;
  display: inline-block;
  color: $primary3;
  /*border-bottom: 1px dotted #ccc;
    color: #006080; */
}

.tooltip .tooltiptext.light {
  background-color: #ffffff;
  color: #333333;
  // border: 1px solid rgba(0,0,0,.2);
}

.tooltip .tooltiptext.dark {
  background-color: #555555;
  color: #ffffff;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.4s;
  box-shadow: 0 2px 13px 1px rgb(112 112 112 / 25%);

  font-size: 15px;
  color: gray;

  background-color: white;
  padding: 3px 20px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  width: 280px;
  height: 145px;
  border-radius: 10px;
  // visibility: visible;
  // opacity: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-right {
  top: -5px;
  left: 125%;
}

.tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  // border-width: 5px;
  // border-style: solid;
  // border-color: transparent #555 transparent transparent;
}

.tooltip-bottom {
  top: 135%;
  left: 10%;
  margin-left: -60px;
}

.tooltip-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  // border-width: 11px;
  // border-style: solid;
  // border-color: transparent transparent #55555530 transparent;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  // border-width: 5px;
  // border-style: solid;
  // border-color: #555 transparent transparent transparent;
}

.tooltip-left {
  top: -5px;
  bottom: auto;
  right: 128%;
}
.tooltip-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  // border-width: 5px;
  // border-style: solid;
  // border-color: transparent transparent transparent #555;
}

.arrow {
  position: absolute;
  left: 48%;
  top: -10px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 1;
}
