@import "../../../styles/index.scss";

.field__wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% / 6);

  @media (max-width: 800px) {
    width: 100%;
  }

  .label_wrapper {
    margin-bottom: 10px;

    .field__label {
      display: block;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .error {
    display: flex;
    display: -webkit-flex;
    color: $red;
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .error-field {
    color: $red;
    border: 1px solid $red;
  }
}
