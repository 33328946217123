@import "../../../../../../styles/index";

.hospitals__wrapper {
  .first__row {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;

    .title {
      font-size: 2rem;
      font-weight: $font-weight-middle;
      color: $white;
    }

    .icon__wrapper {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      border-radius: 5px;
      padding: 2px 4px;
      width: 30px;
      height: 30px;
      color: $primary-pale1;
      background-color: $white;

      img {
        max-width: 100%;
        object-fit: none;
      }
    }
  }

  .column__header {
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    color: $white;
    margin: 30px 0 20px;
  }

  .column__list {
    margin-left: 20px;

    .list__item {
      font-size: 1.2rem;
      font-weight: $font-weight-normal;
      color: $white;
      margin-bottom: 5px;
      line-height: 20px;
    }
  }
}
