@import "../../styles/index.scss";

.report__algorithm__section__wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  // overflow: auto;
  // height: 100%;

  .download {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    position: fixed;
    padding: 15px 20px;
    bottom: 25px;
    right: 25px;
    z-index: 1000;
    cursor: pointer;
    background-color: $primary;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    p {
      margin-left: 10px;
      color: $white;
      font-size: 20px;
      font-weight: $font-weight-bold;
      text-align: left;
    }

    img {
      width: 20px;
    }
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #c7c7e1;
  }

  .section_title_wrapper {
    padding: 50px 70px 0;

    @media (max-width: 960px) {
      padding: 50px 20px 0;
    }
  }

  .report__algorithm__section {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    .section__header {
      width: 100%;
      margin-bottom: 15px;
    }

    .count__results {
      padding-top: 60px;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;

      .count__results__text {
        margin-left: 5px;
        font-size: 20px;
        font-weight: $font-weight-middle;
        color: $primary2;
      }

      &__icon {
        color: $primary2;
      }
    }

    .text__block {
      //padding: 0 10px;
      padding: 100px 70px 0px;

      @media (max-width: 840px) {
        padding: 100px 20px 0px;
      }

      .header__text {
        font-size: 48px;
        font-weight: $font-weight-middle;
        text-align: center;
        color: $primary2;
        margin-bottom: 10px;
      }

      .secondary__text {
        font-size: 20px;
        font-weight: $font-weight-middle;
        color: $primary-pale2;
        text-align: center;
        margin-bottom: 10px;

        &:nth-child(3) {
          margin-bottom: 60px;
        }
      }
    }
  }

  .no_pics_modal {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translate(-50%);
    background: #ffff;
    width: 300px;
    height: 150px;
    border-radius: 30px;
    z-index: 100;
    padding: 40px 40px;
    text-align: center;
    box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0s ease 0.6s;

    div {
      font-weight: 500;
    }
  }

  .show_pics_modal {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
}

.report__algorithm__section__mbl {
  .report__algorithm__section {
    .count__results {
      padding: 60px 70px;
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      gap: 10px;

      // @media (max-width: 500px) {
      //   padding-top: 80px;
      // }

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}
