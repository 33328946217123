@import "../../styles/index.scss";

.payments-credits__section {
  @media (max-width: 840px) {
    padding: 20px;
  }

  .head_box {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(6, 1fr);
    -webkit-grid-gap: 20px;
    margin-bottom: 50px;
  }

  .table__wrapper {
    background-color: $white;
    margin-bottom: 35px;
    padding: 10px;
    border-radius: 10px;
  }
}
