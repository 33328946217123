@import "../../../../styles/index.scss";

.container__inner {
  display: grid;
  //grid-template-columns: repeat(2, 1fr);
  grid-template-columns: 0.7fr 1fr;
  -webkit-display: grid;
  -webkit-grid-template-columns: 0.7fr 1fr;
  width: 100%;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background: $auth-gradient;

  .forgot-pwd__container {
    display: flex;
    display: -webkit-flex;
    height: 100%;
    align-items: center;
    -webkit-align-items: center;
    color: $primary3;
    flex-direction: column;
    -webkit-flex-direction: column;
    //justify-content: center;
    padding: 10% 20px 75px;
    gap: 60px;

    @media (max-width: 840px) {
      padding-top: 46px;
    }

    .forgot-pwd__image {
      // width: 100%;
      max-width: 290px;

      @media (max-width: 840px) {
        text-align: center;
      }

      img {
        width: 100%;
      }
    }

    .forgot-pwd-form__wrapper {
      width: 100%;
      max-width: 375px;
      // transform: translateY(-25%);

      h1 {
        margin-bottom: 40px;
        line-height: 1.3;
        max-width: 310px;
      }

      .forgot-pwd__form {
        display: flex;
        display: -webkit-flex;
        width: 100%;
        flex-direction: column;
        -webkit-flex-direction: column;

        & > div {
          margin-bottom: 30px;
        }
      }

      .text {
        & > * {
          font-weight: 500;
          margin-bottom: 8px;
        }

        .text__email {
          color: $primary;
          font-weight: 600;
        }

        ul {
          list-style: none;
          margin-bottom: 20px;

          li {
            font-weight: 500;
          }
        }
      }

      .submit-btn {
        margin-top: 40px;
        margin-bottom: 50px;
      }
    }
    .forgot-pwd-form__wrapper-mobile {
      padding: 0 20px;
      transform: none;
    }
  }
}

.container__inner-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  -webkit-display: grid;
  -webkit-grid-template-columns: repeat(1, 1fr);
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.right_div {
  height: 100%;
  background-repeat: repeat-y;
  background-size: contain;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  justify-content: center;
  -webkit-justify-content: center;
  padding-bottom: 20px;

  .partner {
    margin-left: 30%;
    text-align: center;
  }

  .trust_copy {
    color: white;
    margin-left: 35%;
    margin-top: 24px;
    text-align: center;
  }
}
