@import "../../../../styles/index";

.area__services__wrapper {
  padding: 35px 50px;

  .header__text {
    text-align: center;
    margin-bottom: 30px;
  }

  .area__services__main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(3, 1fr);
    -webkit-grid-column-gap: 15px;
    -webkit-grid-row-gap: 15px;

    .general__card {
      background: $white1;
      border-radius: 10px;
      height: auto;
    }

    .chart_info {
      position: absolute;
      top: 163px;
      font-size: 12px;
      left: 80px;
    }

    .education {
      grid-column: 1/4;
    }

    // .surface__area {
    //   grid-area: surface;
    //   -webkit-grid-area: surface;
    // }

    // .residents {
    //   grid-area: residents;
    //   -webkit-grid-area: residents;
    // }

    // .family {
    //   grid-area: family;
    //   -webkit-grid-area: family;
    // }

    .foreigners {
      grid-column: 1/4;
    }
  }
}
