@import "../../../../../../styles/index.scss";

.label__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 3px 20px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  width: 80px;
  height: 45px;
  border-radius: 10px;

  .label__text {
    color: #436e76;
    font-size: 12px;
    font-weight: 500;
    margin: auto;
  }

  .label__value {
    color: $gray;
    font-size: 15px;
    font-weight: 500;
    margin: auto;
  }
}
