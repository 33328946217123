@import "../../styles/index.scss";

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $white2;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-direction: column;
  -webkit-flex-direction: column;
  padding: 10px 0;
  z-index: 90006576567567567;

  .slider__wrapper {
    width: 100%;
    height: 33vh;
    max-width: 100vw;
    overflow: hidden;
  }

  .loader__text {
    text-align: center;
  }
}
