@import "../../../../../../styles/index";

.table {
  width: 100%;
  background-color: $white1;
  border-radius: 30px;
  border-spacing: 0px !important;
  border: 1px solid #daebee;
  margin-top: 30px;
  min-width: 650px;

  thead {
    background-color: $primary-pale;
    color: $white1;

    th {
      font-size: 18px;
      font-weight: 600;
      padding: 15px;
    }

    th:not(:last-child) {
      border-right: 1px solid #daebee;
    }
  }

  tbody {
    tr {
      text-align: center;

      td {
        font-size: 14px;
        border-bottom: 1px solid #daebee;
        padding: 15px;
      }
    }
  }

  tr:last-child > td {
    border-bottom: none;
  }
}
