@import "../../../../../../styles/index.scss";

.registration-form__wrapper {
  height: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  width: 100%;
  max-width: 395px;
  margin: 0 auto;
  padding: 10% 10px 75px;
  align-items: center;

  @media (min-width: 840px) and (max-width: 960px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 840px) {
    padding-top: 46px;
  }

  @media (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h1 {
    margin-bottom: 20px;
    padding-top: 45px;
    width: 100%;
  }

  .mondoredLogo {
    width: 200px;
    margin-bottom: 40px;
  }

  .logo {
    display: flex;
    display: -webkit-flex;
    width: 145px;
    height: 56px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .registration-form__image {
    // width: 100%;
    max-width: 290px;

    @media (max-width: 840px) {
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

  .inner__link {
    text-align: center;
    margin-top: 20px;

    .link {
      margin-top: 15px;
      font-weight: $font-weight-bold;
      font-size: 18px;
      text-align: center;
    }

    p {
      text-align: center;
      margin-top: 12px;
      margin-bottom: 5px;
    }

    margin-bottom: auto;
  }

  .steps__wrapper {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

    .step {
      width: 30px;
      height: 30px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      font-size: 24px;
      font-weight: 600;
      color: #ffff;
      background-color: $primary3;
      opacity: 0.5;
      border-radius: 5px;
    }

    .step:not(:last-child) {
      margin-right: 25px;
    }

    .step__active {
      opacity: 1;
    }
  }

  .registration__form {
    margin-top: 5px;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;

    .field__label {
      font-size: $base-font-size;
      display: block;
      margin-bottom: 5px;
      color: $primary3;
    }

    .policy {
      font-size: 14px;
      line-height: 14px;
      color: $primary3;
    }
  }

  .link {
    text-decoration: underline;
    color: $primary3;
    font-size: 13px;
    font-weight: 600;
  }
}

.message-success {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  text-align: center;
  max-width: 375px;
  margin: 0 auto;

  h2 {
    margin-bottom: 25px;
  }

  a {
    color: $primary3;
  }
}
