@import "../../../../styles";

.area__services__wrapper {
  padding: 0px 70px 50px;

  @media (max-width: 840px) {
    padding: 0px 20px 50px;
  }

  .header__text {
    margin-bottom: 40px;

    p {
      font-size: 36px;
      font-weight: $font-weight-middle;
    }
  }

  // .secondary__text {
  //   text-align: center;
  //   font-size: 20px;
  //   font-weight: $font-weight-middle;
  //   color: $primary-pale2;
  //   margin-bottom: 60px;
  // }

  .area__services__main {
    display: grid;
    grid-template-areas:
      "popchange popchange popchange popchange popchange popchange gender gender gender"
      "density density density status status status status status status"
      "poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid"
      "residents residents residents surface surface surface family family family"
      "education education education education education education foreigners foreigners foreigners";
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    -webkit-display: grid;
    -webkit-grid-template-areas: "popchange popchange popchange popchange popchange popchange gender gender gender"
      "density density density status status status status status status"
      "poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid poppyramid"
      "residents residents residents surface surface surface family family family"
      "education education education education education education foreigners foreigners foreigners";
    -webkit-grid-template-columns: repeat(9, 1fr);
    -webkit-grid-column-gap: 30px;
    -webkit-grid-row-gap: 15px;

    @media (max-width: 1000px) {
      display: block;
    }

    .general__card {
      background: $white1;
      border-radius: 30px;
      height: auto;

      &_container {
        position: relative;

        .chart_info {
          position: absolute;
          top: 76px;
          padding: 0px 30px;
          font-size: 16px;
          line-height: 18px;

          @media (max-width: 1000px) {
            font-size: 12px;
          }

          b {
            font-size: 17px;

            @media (max-width: 1000px) {
              font-size: 13px;
            }
          }
        }
      }

      @media (max-width: 1000px) {
        &:not(:last-child) {
          margin: 20px 0;
        }
        &:last-child {
          margin: 20px 0 0;
        }
      }
    }

    .gender__distribution__area {
      grid-area: gender;
      -webkit-grid-area: gender;
    }

    .population__density__area {
      grid-area: density;
      -webkit-grid-area: density;
    }

    .age__distribution__area {
      grid-area: age;
      -webkit-grid-area: age;
    }

    .household__area {
      grid-area: household;
      -webkit-grid-area: household;
    }

    .surface__area {
      grid-area: surface;
      -webkit-grid-area: surface;
    }

    .population__area {
      grid-area: population;
      -webkit-grid-area: population;
    }

    .gender__status {
      grid-area: status;
      -webkit-grid-area: status;
    }

    .pop__change {
      grid-area: popchange;
      -webkit-grid-area: popchange;
    }

    .foreigners {
      grid-area: foreigners;
      -webkit-grid-area: foreigners;
    }

    .pop__pyramid {
      grid-area: poppyramid;
      -webkit-grid-area: poppyramid;
    }

    .residents {
      grid-area: residents;
      -webkit-grid-area: residents;
    }

    .family {
      grid-area: family;
      -webkit-grid-area: family;
    }

    .education {
      grid-area: education;
      -webkit-grid-area: education;
    }
  }
}
