@import "../../../../styles";

.charts_legend {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;

  &__first,
  &__second {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    // font-size: 16px;
  }

  span {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }

  .light {
    background-color: $area-charts-curve;
  }

  .dark {
    background-color: $weak-bar;
  }
}
