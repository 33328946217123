@import "../../../../../styles/index";

.sold_form {
  &__formik {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 40px;
    background-color: $white1;
    padding: 20px;
    border-radius: 10px;

    @media (max-width: 1150px) {
      flex-direction: column;
      gap: 20px;
    }

    &__fields {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;

      @media (max-width: 800px) {
        flex-direction: column;
        width: 100%;
        gap: 20px;
      }
    }

    &__field {
      width: calc(100% / 6);
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (max-width: 800px) {
        width: 100%;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      @media (min-width: 1151px) {
        margin-top: 24px;
        align-self: flex-start;
      }

      button:nth-of-type(1) {
        color: $section-button !important;
      }

      button:nth-of-type(2) {
        background-color: $section-button !important;
      }
    }
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
      background-color: $section-button !important;
    }
  }
}
