@import "../../../../../../styles";

.foreigners_chart__wrapper {
  width: 100%;
  background-color: $white1;
  border-radius: 30px;
  padding: 35px 0px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;

  b {
    width: 90%;
  }

  b,
  .medium,
  .caption {
    padding: 0 30px;
  }

  b {
    @media (min-width: 675px) and (max-width: 1000px) {
      margin-bottom: 25px;
    }
  }

  .foreigners_svg {
    // overflow-x: auto;
    // overflow-y: hidden;
    position: relative;

    // @media (min-width:1000px) {
    //     margin-top: 25px;
    //   }

    svg {
      width: 100%;
    }
  }

  .medium {
    text-transform: uppercase;
    font-size: 36px;
    text-align: center;
    font-weight: $font-weight-middle;
    margin-top: -15px;
  }

  .caption {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #9d9d9d;
    margin-bottom: 50px;
    text-align: center;

    @media (min-width: 550px) and (max-width: 1000px) {
      margin-bottom: 30px;
      margin-top: 20px;
    }

    @media (max-width: 549px) {
      margin-bottom: 30px;
    }

    span {
      font-size: 22px;
      font-weight: inherit;
    }
  }

  .chart__source {
    margin-top: 15px;
    font-size: 14px;
    text-align: end;
    position: absolute;
    bottom: 20px;
    right: 30px;
  }

  .linear_stop {
    stop-color: $foreigners-chart;
  }

  .linear_stroke {
    stroke: $primary3;
  }
}
