@import "../../../../styles/index.scss";

.third_page {
  padding: 50px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;

  .hr {
    width: 100%;
    height: 1px;
    background-color: $primary3;
    margin: 30px 0;
  }

  &__assumptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    &__header {
      &__title {
        margin-bottom: 20px;
      }

      &__price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        &__values {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;

          &__main {
            font-size: 36px;
            font-weight: 700;
            color: $primary3;
          }

          &__div_head {
            width: 20px;
            height: 1px;
            background-color: $primary3;
          }

          &__square {
            font-size: 24px;
            font-weight: 500;
            color: $primary3;

            span {
              color: inherit;
            }
          }
        }

        &__text {
          font-size: 20px;
          color: $primary3;
          width: 263px;
          text-align: center;

          b {
            color: inherit;
          }
        }
      }
    }

    &__stima {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .price_data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 100%;

        &__div_head {
          width: 100%;
          height: 5px;
          background-color: $white1;
          // opacity: 0.2;
        }

        &__title {
          color: $primary3;
          font-weight: 500;
        }

        &__cards {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          @media (max-width: 585px) {
            flex-direction: column;
          }

          &__probabilita {
            background-color: $white1;
            width: 265px;
            height: 100px;
            border-radius: 20px;
            padding: 20px 30px;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            justify-content: space-between;
            -webkit-justify-content: space-between;

            @media (max-width: 920px) {
              width: 280px;
              justify-self: flex-end;
            }

            @media (max-width: 450px) {
              width: 100%;
            }

            & > p {
              color: #787878;
              font-size: 15px;
            }

            &__card {
              position: relative;
              height: 100%;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              -webkit-align-items: center;
              justify-content: center;
              -webkit-justify-content: center;
            }
          }

          &__consigliato {
            background-color: $white1;
            width: 265px;
            height: 100px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 0px 20px;

            @media (max-width: 920px) {
              width: 280px;
              justify-self: flex-start;
            }

            @media (max-width: 450px) {
              width: 100%;
            }

            &__div_head {
              width: 20px;
              height: 1px;
              background-color: $primary2;
            }

            &__price {
              font-size: 24px;
              font-weight: 500;
              color: $primary2;
            }

            &__priceM2 {
              font-size: 16px;
              font-weight: 500;
              color: $primary2;

              span {
                font-size: 14px;
                font-weight: inherit;
                color: inherit;
              }

              sup {
                font-size: 8px;
                font-weight: inherit;
                color: inherit;
              }
            }
          }
        }

        &__gsm {
          font-weight: 500;
          font-size: 30px;
          color: $primary3;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    &__text {
      text-align: justify;
      font-size: 15px;
      font-weight: 500;
      color: $primary-pale2;
      line-height: 1.3;
      width: 548px;
    }
  }
}
