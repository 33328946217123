@import "../../../../../../styles/index";

.image__main {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  flex-direction: column-reverse;
  gap: 20px;

  @media (min-width: 800px) {
    flex-direction: row;
    height: 325px;
    gap: 0px;
  }

  .modal-image__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: calc(100% - 80px);
      height: 100%;
      object-fit: contain;
      border-radius: 5px;
    }
  }

  .image__info {
    width: 40%;
    margin-left: 20px;
    position: relative;

    @media (max-width: 800px) {
      width: 100%;
      margin-left: 0px;
    }

    .add-image-btn__wrapper {
      position: absolute;
      //bottom: 0;
      right: 0;

      & > button {
        img {
          transform: rotate(45deg);
        }
      }
    }

    .actions__wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      // margin-bottom: 20px;

      & > *:first-child {
        margin-right: 10px;
      }
    }

    .edit-image__form {
      display: flex;
      flex-direction: column;
      height: calc(100% - 45px);

      div:nth-child(2) {
        margin: 0;

        textarea {
          margin: 0;
        }
      }

      input {
        font-weight: $font-weight-bolder;
      }

      textarea {
        font-size: 14px;
      }
    }

    .form__disabled {
      div > textarea:disabled,
      div > input:disabled {
        background-color: transparent;
        padding: 0;
        color: $primary-pale;
      }

      div > textarea:disabled {
        padding: 15px 0;
      }
    }
  }

  .image__wrapper {
    width: 60%;
    position: relative;
    // max-width: 800px;
    // cursor: pointer;

    @media (max-width: 800px) {
      width: 100%;
    }

    .cover__image {
      position: absolute;
      left: 20px;
      top: 20px;
    }

    .house-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 5px;
      border: 1.5px solid $primary;
    }

    // &>button {
    //   position: absolute;
    //   right: 10px;
    //   top: 10px;

    &_edit {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 40px;
      width: 40px;
      padding: 10px;
      // border: 2px solid $primary2;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    // }

    .dropdown__menu {
      position: absolute;
      right: 20px;
      top: 60px;
      width: auto;
      padding: 10px 0;
      height: auto;
      border-radius: 10px;
      background-color: $white;
      display: flex;
      flex-direction: column;

      .menu__item {
        text-align: left;
        padding: 10px;
        cursor: pointer;
      }

      .remove-item-btn {
        color: $section-button;
      }

      .menu__item:hover {
        background: $white2 !important;
      }
    }
  }
}

.empty-gallery {
  padding: 0 0 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-left: 1%;

  & > b {
    margin-bottom: 15px;
  }

  .add-image-btn__wrapper {
    & > button {
      img {
        transform: rotate(45deg);
      }
    }
  }
}

.change-cover-modal__title {
  font-weight: $font-weight-bolder;
}

.change-cover-modal__actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 10px;
  }
}

.input__label {
  color: $primary1;
  margin-bottom: 5px;
  width: 500px;
}

.add-image-btn__wrapper {
  & > button {
    background-color: $section-button !important;
  }
}
