@import "../../../../styles/index.scss";

.container__inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  -webkit-display: grid;
  -webkit-grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    -webkit-grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  .payment_container {
    color: $primary3;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    gap: 60px;
    height: 100%;
    padding: 60px;

    .info_container {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: center;
      -webkit-justify-content: center;
      gap: 20px;
      -webkit-gap: 20px;
      padding-bottom: 60px;
    }

    h1 {
      font-size: 2rem;
    }

    h1:first-of-type {
      color: rgb(0, 218, 0);
      font-size: 3rem;
    }

    button {
      @media (max-width: 480px) {
        font-size: 1.5rem !important;
      }
    }
  }
}
