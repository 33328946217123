@import "../../../../styles/index";

.area__services__wrapper {
  padding: 35px 50px;

  .header__text {
    text-align: center;
    margin-bottom: 40px;
  }

  .services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .service {
      &_title {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-bottom: 10px;

        img {
          width: 22px;
          margin-right: 5px;
        }
      }

      &_content {
        margin-left: 26px;
        display: flex;

        &_distance,
        &_name {
          display: flex;
          flex-direction: column;
          width: max-content;
          align-items: flex-end;

          p {
            font-size: 12px;
          }

          &_title {
            font-weight: 600;
            margin-bottom: 2px;
          }

          &_content {
            line-height: 14px;
          }
        }

        &_distance {
          margin-right: 4px;
        }

        &_name {
          align-items: flex-start;
          border-left: 1px solid #9d9d9d;
          padding-left: 4px;

          &_content {
            font-weight: 500;
          }
        }
      }
    }
  }
}
