@import "../../../../styles/index";

#compare_pg {
  .leaflet-container {
    height: 325px;
    width: 100%;
    border-radius: 10px;
  }

  .svg-icon-leaflet {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-interactive {
    stroke: $primary;
    stroke-width: 2;
    fill: none;
  }
}
