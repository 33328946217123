@import "../../../styles/index.scss";

.banner__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;

  .image__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;

    .banner__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      aspect-ratio: 1/1;
    }
  }

  .banner__inner {
    z-index: 2;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    gap: 90px;
    text-align: center;
    padding: 60px 60px 40px;

    @media (max-width: 1000px) {
      padding: 70px 60px 20px;
    }

    * {
      font-size: $base-font-size;
      color: #ffff;
    }

    p {
      line-height: 1.8;

      @media (max-width: 450px) {
        line-height: 1.5;
      }
    }

    .logo {
      display: flex;
      display: -webkit-flex;
      width: 250px;
    }

    .inner__text {
      max-width: 390px;

      h2 {
        font-size: 32px;
        margin-bottom: 15px;
      }
    }

    .inner__link {
      .link {
        display: block;
        margin-top: 10px;
        font-weight: $font-weight-bold;
        font-size: 24px;
      }
    }
  }
}
