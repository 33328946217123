@import "../../styles/index.scss";

.account__wrapper {
  background-color: $white;
  padding: 25px 25px 50px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;

  .form__wrapper {
    .field__wrapper {
      display: flex;
      display: -webkit-flex;
      align-items: flex-end;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      margin-bottom: 50px;
    }

    .edit-account__form {
      display: flex;
      display: -webkit-flex;
      align-items: flex-end;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      margin-bottom: 30px;

      label {
        font-size: 18px;
      }

      & > div:first-child {
        width: 50%;
      }

      .form__actions {
        display: flex;
        display: -webkit-flex;
        margin-bottom: 24px;
        gap: 10px;
      }
    }
  }
}

.account-image {
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  -webkit-justify-content: center;
  padding-bottom: 40px;

  &__img {
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    // border: 1px solid $primary;

    .profile_pic {
      border-radius: 50%;
      width: 240px;
      height: 240px;
      object-fit: cover;
      border: 1px solid $primary;
    }

    .edit_pic {
      position: absolute;
      top: 0;
      right: 35px;
    }
  }
}

.modify_btn {
  width: 40px;
  height: 40px;
  background: $violet;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
}

.account-image-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;

  background-color: #ffffff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  font-size: 30px;
  color: #000;
  margin-right: 20px;
}

.account-image-input {
  display: none;
}

.account__wrapper-mobile {
  padding: 24px 24px 100px;

  .form__wrapper {
    .field__wrapper {
      margin-bottom: 26px;
    }

    .edit-account__form {
      margin-bottom: 26px;

      label {
        font-size: 13px;
      }

      & > div:first-child {
        width: 70%;
      }
    }
  }
}
