@import "../../../../../styles/index";

.selects_tooltip {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  color: $primary3;

  .selects_tooltip__text {
    margin-left: 5px;
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $primary2;
  }
}

.selects {
  margin: 17px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-width: 1400px;
}
