@import "../../../styles/index.scss";

@media print {
  .footer {
    display: none !important;
  }
}

.footer {
  height: 20px;
  padding-bottom: 4px;
  display: block;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  background: #03434922;
  z-index: 10000;
  backdrop-filter: blur(6px);

  .link {
    color: $black;
    text-decoration: none;
    font-size: 13px;
    transition: color 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      color: $primary;
    }
  }
}
