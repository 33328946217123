@import "../../../styles/index.scss";

.checkbox__wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  padding: 14px 10px;
  position: relative;

  span {
    font-size: 16px;
  }

  .checkbox__field {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid $checkbox-button;
    background-color: $white;
    appearance: none;
    -webkit-appearance: none;
    display: grid;
    place-content: center;

    &:before {
      content: "✔";
      font-size: 12px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 2em 2em $checkbox-button;
    }

    &:checked {
      &:before {
        transform: scale(1);
      }
    }
  }

  // .checkbox__field {
  //   position: relative;
  //   width: 20px;
  //   height: 20px;
  //   padding: 0;
  //   //border: none;
  //   cursor: pointer;
  //   margin-right: 10px;

  //   &,
  //   &:checked {
  //     &::after {
  //       content: "\2713";
  //       position: absolute;
  //       top: -2px;
  //       left: -2px;
  //       width: 20px;
  //       height: 20px;
  //       border-radius: 3px;
  //       display: flex;
  //       display: -webkit-flex;
  //       align-items: center;
  //       -webkit-align-items: center;
  //       justify-content: center;
  //       -webkit-justify-content: center;
  //       font-size: 22px;
  //     }
  //   }

  //   &::after {
  //     border: 2px solid $primary3;
  //     background-color: #ffff;
  //     color: $primary3;
  //     content: "";
  //   }
  // }
}

.error {
  display: flex;
  display: -webkit-flex;
  color: $red;
  font-size: 13px;
  line-height: 1.2;
  margin-bottom: 10px;
}
