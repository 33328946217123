@import "../../styles/index.scss";

.report__wrapper {
  border-radius: 10px;
  background-color: $white;
  padding: 40px 10px 10px;
  border: 1px solid $primary;

  .report__header {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 30px;
  }

  .span__table_report_oltre {
    background-color: #f2dfe3;
    margin-top: 4px;
    padding: 7px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 8px;
    color: #a62947;
  }

  .span__table_report_entro {
    background-color: #dff2e7;
    margin-top: 4px;
    padding: 7px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 8px;
    color: #29a661;
  }

  .copy_btn {
    background: $primary;
    cursor: pointer;

    button {
      width: 100% !important;
    }
  }
}
