@import "../../../styles/index.scss";

.no_pics_modal {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translate(-50%);
  background: #ffff;
  width: 300px;
  height: 150px;
  border-radius: 30px;
  z-index: 10000;
  padding: 40px 40px;
  text-align: center;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0s ease 0.6s;

  &_stripe {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    background-color: $primary-pale;
    width: 100%;
  }

  &_text {
    font-weight: 500;
  }
}

.show_pics_modal {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease;
}
