@import "../../../../styles/index";

.gauge-chart {
  .recharts-radial-bar-background-sector {
    fill: $primary-pale3 !important;
  }

  .recharts-radial-bar-sectors {
    path[name="Example"] {
      fill: $primary-pale3;
    }

    path[name="Social demographic"] {
      fill: $full-gauge;
    }
  }
}
