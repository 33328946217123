@import "../../../styles/index.scss";

.progressbar {
  --percent-html: var(--percent-html);
  position: relative;
  width: 130px;
  height: 130px;
  z-index: 10;

  .progressbar__svg {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .progressbar__svg-stroke {
    stroke: $progress-bar-stroke;
  }

  .progressbar__svg-circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 8;
    stroke-dasharray: 315 315;
    stroke-dashoffset: 315;
    stroke: $primary1;
    filter: drop-shadow(0 0 2px $primary1);
    transform: translate(5px, 5px);
    animation: anim_circle-html 2s ease-in forwards;
  }

  .progressbar__text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 55%;
    height: 55%;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    font-size: 24px;
    background-color: $progress-bar-bg;
    color: $progress-bar-text;
  }

  .progressbar__pointer {
    position: absolute;
    top: 0;
    left: 110%;
    height: 100px;
    width: 1px;
    background-color: #5e239d;
    transform: translate(0, 15%) rotate(90deg);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      transform: translate(-50%, -50%);
      background-color: #5e239d;
    }
  }

  .pointer__text {
    position: absolute;
    top: 42%;
    left: 65%;
    transform: rotate(90deg);
    width: 100%;
    color: $white;
    font-size: 22px;
    text-align: center;
  }
}

@keyframes anim_circle-html {
  100% {
    stroke-dashoffset: var(--percent-html);
  }
}
