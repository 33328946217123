@import "../../../styles/index.scss";

.field__wrapper {
  margin-bottom: 10px;
  position: relative;

  .field__textarea,
  .input-group {
    width: 100%;
    margin-bottom: 10px;
    color: $primary3;
    padding: 10px;
    border: 1px solid $primary;
  }

  .field__textarea,
  .input-group {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    transition: outline 0.05s ease;

    &:hover:not(.error-field) {
      outline: 1px solid $primary;
    }

    &:focus-visible:not(.error-field) {
      outline: 1px solid $primary;
    }
  }

  // .field__textarea,
  .field__input {
    width: 100%;
    border: none;
    color: $primary3;
    font-size: 18px;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: #aeaeb2;
    }
  }

  .field__textarea {
    resize: vertical;
    min-height: 50px;
    padding-top: 15px !important;
    padding-left: 10px !important;
  }

  .field__textarea:disabled {
    resize: none;
  }

  .label_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .field__label {
    display: block;
    font-weight: 600;
    font-size: 13px;
  }

  .error {
    display: flex;
    display: -webkit-flex;
    color: $red;
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .error-textarea,
  .error-field {
    color: $red;
    border: 1px solid $red;

    &:hover {
      outline: 1px solid $red;
    }

    &:focus-visible {
      outline: 1px solid $red;
    }
  }
}

.full-height {
  height: 100%;

  .field__textarea {
    height: calc(100% - 24px);
  }
}

input[type="password"i] {
  color: $primary3 !important;
}

input[type="password"i].error-field {
  color: $red !important;
}

.input-group {
  display: flex;
  display: -webkit-flex;
  padding-right: 10px;
}

.show-pwd-icon {
  cursor: pointer;
}

.descNumber {
  font-size: 14px;
}
