@import "../../../../styles/index";

.compare_page_wrapper {
  padding: 35px 50px;

  .header__text {
    text-align: center;
    margin-bottom: 10px;
  }

  .secondary__text {
    font-size: 15px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: center;
    margin-bottom: 30px;
  }

  .no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    color: $primary2;
    width: 100%;
    background-color: $white1;
    padding: 40px;
    border-radius: 10px;
  }

  .map_container {
    .map_title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .map {
      width: 100%;
      height: 325px;
      position: relative;

      &__legend {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 10px 8px;
        border-radius: 5px;
        z-index: 1000;
        font-size: 13px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .map_legend {
      display: grid;
      grid-template-columns: 2fr 2.3fr 0.6fr;
      align-items: center;
      row-gap: 10px;
      // gap: 1px;

      p {
        font-size: 8px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          width: 12px;
        }
      }
    }
  }

  .table_container {
    margin-bottom: 30px;

    .table {
      width: 100%;
      background-color: $white1;
      border-spacing: 0px !important;
      border-radius: 10px;
      min-width: 650px;
      margin-bottom: 10px;

      thead {
        background-color: $primary-pale;
        color: $white1;

        th {
          font-size: 13px;
          font-weight: 500;
          padding: 8.5px 10px;
          line-height: 1.1;
        }

        th:not(:last-child) {
          border-right: 1px solid #daebee;
        }
      }

      tbody {
        tr {
          text-align: center;
          height: 55px;

          td {
            font-size: 13px;
            border-bottom: 1px solid #daebee;
            padding: 0px 15px;
          }

          // td:first-child {
          //   padding: 0px;
          //   display: flex;
          //   justify-content: flex-end;

          //   img {
          //     height: 55px;
          //     width: 100%;
          //   }
          // }

          .price_column {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: inherit;

            b {
              font-size: inherit;
            }

            img {
              width: 9px;
            }
          }
        }
      }

      tr:last-child > td {
        border-bottom: none;
      }
    }

    .legend {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 20px;
      margin-bottom: 10px;

      &__text {
        font-size: 12px;

        img {
          width: 10px;
        }

        sup {
          font-size: 8px;
        }
      }
    }
  }
}

.arrow_img {
  transform: rotateX(180deg);
}
