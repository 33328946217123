@import "../../../../styles/index";

.area__services__wrapper {
  padding: 50px 70px;

  @media (max-width: 960px) {
    padding: 50px 20px;
  }

  .area__services__header {
    font-size: 36px;
    font-weight: $font-weight-middle;
    color: $primary2;
    text-align: center;
  }

  .area__services__content {
    margin-top: 20px;
    display: grid;
    display: -webkit-grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -webkit-grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    // -webkit-grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    -webkit-grid-gap: 30px;
  }

  .general__card {
    border-radius: 30px;
    padding: 45px 40px;
    // height: fit-content;
  }

  .services__area {
    grid-column: 1 / 3;
    grid-row: 1/2;
    background-color: $white1;
  }

  .transportation__area {
    grid-column: 3 / 5;
    grid-row: 1/5;
    background-color: $primary-pale;
  }

  .hospitals__area {
    grid-column: 1 / 3;
    grid-row: 2/7;
    background-color: $primary-pale;
  }

  .health__area {
    grid-column: 3 / 5;
    grid-row: 5/7;
    background-color: $white1;
  }
}

.area__services__mbl {
  .area__services__content {
    display: block;

    .general__card {
      margin: 20px 0;
      padding: 30px 35px;
    }
  }
}
