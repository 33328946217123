@import "../../../../../../styles/index.scss";

.map {
  height: 100%;
  width: 100%;
  position: relative;

  @media (max-width: 920px) {
    height: 540px;
  }

  // .download {
  //   display: flex;
  //   display: -webkit-flex;
  //   align-items: center;
  //   -webkit-align-items: center;
  //   position: absolute;
  //   padding: 10px 30px;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 1;

  //   cursor: pointer;
  //   background-color: $section-button;
  //   border-radius: 20px 0 0 0;

  //   @media (max-width: 740px) {
  //     padding: 20px 30px;
  //     flex-direction: column;
  //     gap: 8px;
  //   }

  //   p {
  //     margin-left: 10px;
  //     color: $white;
  //     font-size: 20px;
  //     font-weight: $font-weight-bold;
  //     text-align: left;

  //     @media (max-width: 740px) {
  //       margin-left: 0px;
  //     }
  //   }

  //   img {
  //     width: 20px;
  //   }
  // }
}

.marker__wrapper {
  position: absolute;
  color: $primary;

  .marker {
    width: 18px;
    position: relative;

    .marker__content {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $white1;
      border: 1px solid $primary-pale;
      box-shadow: 0px 4px 40px 1px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      min-width: 300px;
      transform: translate(50%, 50%);
      z-index: 100;
      padding-top: 30px;

      & > *:not(:last-child) {
        margin-bottom: 10px;
        font-size: 13px;
      }

      .content__image {
        height: 160px;
        width: 100%;
        color: $section-button;
        display: flex;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .content__inner {
        margin: 15px auto 35px;
        max-width: 80%;

        p {
          display: flex;
          display: -webkit-flex;
          align-items: flex-start;
          -webkit-align-items: flex-start;
          margin-bottom: 10px;

          img {
            width: 18px;
            height: auto;
            object-fit: contain;
            margin-right: 10px;
          }

          span {
            width: 100%;
            text-align: start;
            color: #7a7a7a;
          }
        }
      }

      & > footer {
        background-color: $primary-pale;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        flex-direction: column;
        -webkit-flex-direction: column;
        text-align: center;
        padding: 10px;
        color: $white;
        font-weight: $font-weight-bold;
        font-size: 20px;
        border-radius: 0 0 18px 18px;

        & > p {
          margin-top: 4px;
          font-size: 14px;
        }
      }
    }
  }
}
