@import "../../../../styles/index";

.tooltipStyle {
  position: relative !important;
  cursor: pointer;

  .tooltipBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 50px;
    padding: 15px;
    border-radius: 10px;
    color: $white;
    background: $pdf-button;

    span {
      color: inherit;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;

    input {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.tooltipStyleCharts {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;

  .tooltipBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 40px;
    padding: 15px 10px;
    border-radius: 10px;
    color: $white;
    background: $pdf-button;

    span {
      color: inherit;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;

    input {
      cursor: not-allowed;
    }
  }
}
