@import "../../../../../../styles/index.scss";

.proba_chart {
  .apexcharts-yaxis-label tspan {
    font-size: 16px !important;
    font-weight: 400;
    color: #ffce02;
    font-family: "Roboto", sans-serif;
  }

  .apexcharts-xaxis-label tspan {
    font-size: 16px !important;
    font-family: "Roboto", sans-serif;
  }

  .apexcharts-xaxis-tick {
    stroke-dasharray: 4;
  }

  .chart_menu_box {
    width: fit-content;
  }

  g[seriesName="Probabilitàxdixvenditaxinxbasexallaxstimaxreale"] {
    path {
      stroke: $proba-chart-real;
    }

    .apexcharts-series-markers {
      circle {
        fill: $proba-chart-real;
      }
    }
  }

  g[seriesName="Probabilitàxdixvenditaxinxbasexallexopzionixselezionate"] {
    path {
      stroke: $proba-chart-selected;
    }

    .apexcharts-series-markers {
      circle {
        fill: $proba-chart-selected;
      }
    }
  }

  .apexcharts-graphical {
    linearGradient:first-of-type {
      stop {
        stop-color: $proba-chart-real;

        &:first-of-type {
          stop-opacity: 1;
          // offset: 0 !important;
        }
        &:nth-of-type(2) {
          stop-opacity: 0.5;
          // offset: 0.4 !important;
        }
        &:nth-of-type(3) {
          stop-opacity: 0.1;
          // offset: 0.1 !important;
        }
      }
    }

    linearGradient:nth-of-type(2) {
      stop {
        stop-color: $proba-chart-selected;
        // stop-opacity: 0.1;

        &:first-of-type {
          stop-opacity: 0.4;
          // offset: 0 !important;
        }
        &:nth-of-type(2) {
          stop-opacity: 0.1;
          // offset: 0.4 !important;
        }
        &:nth-of-type(3) {
          stop-opacity: 0.1;
          // offset: 0.1 !important;
        }
      }
    }
  }

  // div[seriesName="Probabilitàxdixvenditaxinxbasexallaxstimaxreale"] {
  //   .apexcharts-legend-marker {
  //     background: $proba-chart-selected !important;
  //     color: $proba-chart-selected !important;
  //   }
  // }

  // div[seriesName="Probabilitàxdixvenditaxinxbasexallexopzionixselezionate"] {
  //   .apexcharts-legend-marker {
  //     background: $proba-chart-real !important;
  //     color: $proba-chart-real !important;
  //   }
  // }
}
