@import "../../../../styles/index";

.pricing_simulator {
  padding: 35px 50px;

  .header__text {
    text-align: center;
    margin-bottom: 10px;
  }

  .secondary__text {
    font-size: 15px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: center;
    margin-bottom: 60px;
  }

  .section_header {
    // font-size: 20px;
    // font-weight: 600;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;

    &__options {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        &__item {
          background-color: $white1;
          padding: 10px;
          width: 200px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;

          .title {
            font-size: 16px;
          }

          .content {
            font-weight: 500;
          }
        }
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .medium_card {
        background-color: $prob-sale-bg !important;
      }

      .best__selling__card {
        padding: 30px 40px;
        background-color: $white1;
        border-radius: 30px;
        min-width: 350px;

        .first__row {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          margin-bottom: 20px;

          .icon__wrapper {
            margin-right: 10px;
            padding: 4px;
            border-radius: 4px;
            width: 20px;
            height: 26.5px;
          }

          .icon_two_months {
            color: $best-selling-card1;
          }

          .icon_six_months {
            color: $best-selling-card2;
          }

          .icon_twelve_months {
            color: $best-selling-card3;
          }

          .first__row__text {
            font-size: 14px;
            font-weight: $font-weight-middle;
            color: $gray;
          }
        }

        .second_row_test {
          color: #9a9a9a;
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 5px;
        }

        .second_row_price {
          font-size: 24px;
        }

        .period {
          font-size: 20px;
          margin-bottom: 10px;
          font-weight: $font-weight-middle;
          color: $gray;
        }

        .price {
          font-size: 44px;
          font-weight: $font-weight-middle;

          span {
            font-size: 24px;
            font-weight: inherit;
            color: inherit;
          }
        }
      }

      .percent_box_positive {
        margin-left: 30px;
        padding: 7px 10px;
        background-color: #dbf6e5;
        border-radius: 30px;
        font-size: 16px;
        color: #628168;
      }

      .percent_box_negative {
        margin-left: 30px;
        padding: 7px 10px;
        background-color: #f6dbdb;
        border-radius: 30px;
        font-size: 16px;
        color: #9c3b3b;
      }
    }
  }
}
