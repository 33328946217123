@import "../../../../styles/index";

.property__characteristics__wrapper {
  padding-top: 20px;

  .title__wrapper {
    padding: 0px 70px;

    @media (max-width: 840px) {
      padding: 0px 20px;
    }
  }

  .property__characteristics__header {
    font-size: 48px;
    font-weight: $font-weight-middle;
    text-align: center;
    color: $primary2;
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .property__characteristics__header_create {
    font-size: 42px;
    margin-bottom: 40px;

    @media (max-width: 721px) {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }

  .property__characteristics__main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(4, 1fr);
    -webkit-grid-gap: 50px;
    background-color: $white1;
    padding: 70px;

    @media (max-width: 950px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
      -webkit-display: flex;
      -webkit-flex-direction: column;
      -webkit-gap: 50px;
    }

    @media (max-width: 450px) {
      padding: 70px 20px;
    }

    .characteristichs__wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;
      -webkit-display: flex;
      -webkit-flex-direction: column;
      -webkit-gap: 30px;
      border: 3px solid $primary;
      border-radius: 10px;
      padding: 20px;
    }

    .characteristichs__title {
      font-size: 22px;
      text-transform: uppercase;
      color: $primary2;
      display: flex;
      align-items: center;
      gap: 10px;

      b {
        font-size: 22px;
        text-transform: uppercase;
        color: $primary2;
      }

      svg {
        width: 26px;
        height: 100%;
      }
    }

    .characteristichs__column {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 30px;
      grid-column-gap: 15px;
      grid-auto-rows: min-content;

      @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
        -webkit-display: flex;
        -webkit-flex-direction: column;
        -webkit-gap: 30px;
      }
    }

    .characteristichs__structure {
      grid-column: 1/5;

      .characteristichs__column {
        @media (min-width: 800px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
    .characteristichs__external {
      grid-column: 1/3;

      svg {
        width: 28px;
      }
    }
    .characteristichs__surfaces {
      grid-column: 3/5;

      .total_surface {
        color: $primary2;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
        grid-column: 1/3;
        justify-self: flex-end;
        border: 2px solid;
        padding: 7px;
        border-radius: 4px;
        width: fit-content;

        b {
          font-size: 22px;
          color: $primary-pale;
          font-weight: 700;
          font-style: italic;
        }
      }

      svg {
        width: 32px;
      }
    }
    .characteristichs__price {
      grid-column: 1/3;
      // gap: 9px;
      // -webkit-gap: 9px;

      svg {
        width: 40px;
      }
    }

    .three_prices {
      grid-column: 1/5;
      .characteristichs__column {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .column__item {
      .second__column__header {
        color: $primary2;
        font-size: 18px;
        font-weight: $font-weight-bolder;
        margin-bottom: 9px;
      }

      .second__column__value {
        color: $recap-text;
        font-size: 17px;
        font-weight: $font-weight-bolder;
      }
    }

    .image__wrapper {
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;
      flex-direction: column;
      -webkit-flex-direction: column;
      margin: auto;
      color: #10606d;

      @media (max-width: 670px) {
        display: none;
      }

      & > img {
        margin-bottom: 10px;
        height: 40vmax;
        width: 34vmax;
        max-width: 400px;
        max-height: 400px;
      }
    }
  }

  .property__characteristics__main_create {
    padding: 0px 70px;
    height: 100%;
    max-height: calc(100vh - 270px);

    @media (max-width: 1230px) {
      max-height: calc(100vh - 294px);
    }

    @media (max-width: 1100px) {
      padding: 0px 20px;
    }

    @media (max-width: 720px) {
      max-height: calc(100vh - 270px);
    }

    @media (max-width: 550px) {
      max-height: calc(100vh - 294px);
    }

    @media (max-width: 410px) {
      max-height: calc(100vh - 330px);
    }
  }

  .property__characteristics__footer {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    gap: 60px;
    padding: 35px 25px;
    background-color: $primary-pale;
    min-height: 180px;
    align-items: center;
    position: relative;

    @media (max-width: 1000px) {
      min-height: 200px;
    }

    .footer__text {
      font-size: 32px;
      font-weight: $font-weight-middle;
      color: $white;
    }

    .to_top {
      position: absolute;
      top: -60px;
      right: 20px;
      cursor: pointer;
      height: 25px;
      background-color: $section-button;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 20px;
      border-radius: 5px;

      @media (max-width: 650px) {
        padding: 20px 15px;
      }

      img {
        width: 14px;
        color: $white;

        @media (max-width: 650px) {
          width: 16px;
        }
      }

      span {
        color: $white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;

        @media (max-width: 650px) {
          display: none;
        }
      }
    }

    .plus__wrapper {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translate(-50%);
    }

    .footer__actions {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      height: fit-content;

      .actions__item {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        margin: 0 10px;
        cursor: pointer;

        .actions__title {
          font-size: 20px;
          font-weight: $font-weight-bold;
          color: $white;
          margin-left: 15px;
        }
      }
    }
  }

  .estimation {
    .second__column__header {
      // font-size: 20px;
    }

    .second__column__value {
      font-size: 22px !important;
      font-weight: 700 !important;
      font-style: italic;
    }
  }
}

.property__characteristics__mbl {
  .property__characteristics__footer {
    display: block;

    .footer__text {
      margin-top: 40px;

      @media (max-width: 540px) {
        text-align: center;
      }
    }

    .footer__actions {
      margin: 0px;
      margin-top: 15px;
      margin-bottom: 30px;
      justify-content: flex-end;

      @media (max-width: 540px) {
        justify-content: center;
        gap: 20px;
        margin-top: 30px;
      }

      .actions__item {
        margin: 0 8x;

        .actions__title {
          margin-left: 8px;
        }
      }
    }
  }
}
