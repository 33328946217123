@import "../../../../styles/index.scss";

.report_nav {
  position: fixed;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 99%;
  // height: 80px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 10000000;
  display: grid;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;

  @media (max-width: 1200px) {
    display: none;
  }

  &_item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 10px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    height: 100%;
    background-color: $nav-bg;
    // background-color: $white1;
    // color: $primary;
    color: $white;
    transition: all 0.2s;

    // &:not(:last-child) {
    //   border-right: 1px solid $primary;
    // }

    &:hover {
      background-color: $nav-hover;
      color: $primary;
    }
  }

  .active {
    background-color: $nav-active-bg;
    color: white;
  }
}

.show_nav {
  visibility: visible;
  opacity: 1;
}
