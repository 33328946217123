@import "../../../styles/index.scss";

.page-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  height: 100%;
  padding-bottom: 40px;
  background-color: $white2;

  .section__wrapper {
    width: 100%;
    padding-bottom: 48px;
    background-color: $white2;
    // height: 100%;
    // overflow: auto;

    & > section {
      padding: 0 15px;
      width: 100%;
      height: 100%;
    }
  }
}

.page-wrapper-mobile {
  flex-direction: column;
  -webkit-flex-direction: column;
  padding: 0;
  .section__wrapper {
    & > section {
      padding: 0;
    }
  }
}
