.map_section {
  display: grid;
  display: -webkit-grid;
  grid-template-columns: 1fr 1.5fr;
  -webkit-grid-template-columns: 1fr 1.5fr;

  @media (max-width: 920px) {
    display: block;
  }
}
