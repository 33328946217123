@import "../../../../styles/index.scss";

.primary__btn {
  border: none;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-color: $primary;
  gap: 5px;

  & > span {
    font-size: inherit;
    font-weight: $font-weight-bold;
    color: inherit;
  }

  .left-icon {
    // margin-right: 5px;
    height: 100%;
  }

  .right-icon {
    // margin-left: 5px;
    height: 100%;
  }

  &:disabled {
    opacity: 0.5;
  }
}
