@import "../../../styles/index.scss";

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(35px);
  background: rgba(3, 67, 73, 0.25);
  z-index: 111;
}

// .opened_modal {
//   opacity: 1 !important;
//   transition: opacity .2s ease !important;
// }

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  //justify-content: center;
  gap: 40px;
  background: #ffff;
  border-radius: 8px;
  max-height: 100%;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  overflow: hidden;
  // opacity: 0;
  // transition: opacity .2s ease;

  @media (max-width: 1350px) and (max-height: 670px) {
    overflow: auto;
  }

  .modal__header {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    color: $primary3;

    img {
      width: 20px;
    }
  }
}
