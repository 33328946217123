@import "../../../styles/index.scss";

.field__wrapper {
  .field__value {
    width: 100%;
    font-size: 20px;
    border: none;
    padding: 0;
    color: $black;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    align-items: center;
    -webkit-align-items: center;
  }

  .field__label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: $font-weight-bold;
  }
}
