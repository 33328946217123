@import "../../../../styles/index.scss";

.probability__sale__wrapper {
  padding: 50px 70px;

  @media (max-width: 960px) {
    padding: 50px 20px;
  }

  .probability__sale__header {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-bottom: 60px;

    .probability__sale__title {
      font-size: 48px;
      text-align: center;
      margin-bottom: 10px;
      font-weight: $font-weight-middle;
      color: $primary2;
    }

    .probability__sale__secondary__text {
      font-size: 20px;
      // max-width: 840px;
      font-weight: 500;
      color: $primary-pale2;
      text-align: center;
      line-height: 1.2;
    }
  }

  .characteristics__info {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    color: $primary3;

    .characteristics__info__text {
      margin-left: 5px;
      font-size: 16px;
      font-weight: $font-weight-bold;
      color: $primary2;
    }
  }

  .characteristics__selectors {
    margin: 17px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    max-width: 1400px;
  }

  .best__selling {
    margin-top: 30px;
    font-size: 24px;
    font-weight: $font-weight-bold;
    color: $primary2;
  }

  .best__selling__cards {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(291px, 1fr));
    grid-gap: 30px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(auto-fit, minmax(291px, 1fr));
    -webkit-grid-gap: 30px;
    margin: 30px 0;
  }
}

.probability__sale__mbl {
  .best__selling__cards {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    -webkit-grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
