@import "../../../../styles/index";

.section__title {
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  //   justify-content: center;
  //   align-items: center;
  flex-direction: column;
  gap: 10px;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1.5px solid $primary2;

    @media (max-width: 840px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    &__text {
      font-size: 48px;
      font-weight: 500;
      color: $primary2;

      @media (max-width: 840px) {
        text-align: center;
      }
    }

    &__action {
      @media (max-width: 840px) {
        margin-bottom: 10px;
      }
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: justify;
    width: 80%;
    line-height: 1.1;

    @media (max-width: 840px) {
      text-align: center;
      width: 100%;
    }
  }
}
