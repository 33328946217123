$PLATFORM: "HOUSEPLUS"; // NAME PLATFORM

$white: #fff7fa;
$light-gray: #ededed;
$gray: #9a9a9a;
$red: #fd7072;
$rosso: #fc440f;
$violet: #5e239d;
$black: #333333;

$white1: #f4f7f8;
$white2: #ecf7f8;
$white3: #d8e9eb;
$primary: #078ea6;
$primary1: #056f7a;
$primary2: #11606d;
$primary3: #034349;
$primary-pale: #00b1c4;
$primary-pale1: #0aa5b5;
$primary-pale2: #607b7d;
$primary-pale3: #bfeef3;
$best-selling-card1: #bddae0;
$best-selling-card2: #5bcad6;
$best-selling-card3: #3c8a9c;
$section-button: #056f7a;
$progress-bar-bg: #b6dce1;
$progress-bar-text: #056f7a;
$progress-bar-stroke: #bddbe0;
$radar-fill: #00a5b5;
$charts-fill: #bfeef3;
$area-charts-curve: #00b1c4;
$weak-bar: #11606d;
$foreigners-chart: #00a5b5;
$proba-chart-real: #034349;
$proba-chart-selected: #00b1c4;
$slider-rail: #056f7a;
$subscribed-box: #d8e9eb;
$right-omi: #11606d;
$full-gauge: #11606d;
$right-side-title: #fff7fa;
$right-home: #034349;
$right-thumb: #034349;
$pdf-button: #11606d;
$checkbox-button: #034349;
$compared-stop-color: #bfeef3;
$compared-icon-color: #056f7a;
$nav-hover: #bfeef3;
$recap-text: #00b1c4;
$radio-btn-text: #11606d;
$radio-btn-bg: #d8e9eb;
$nav-bg: #00b1c4;
$nav-active-bg: #056f7a;
$prob-sale-bg: #f3feff;
$auth-gradient: linear-gradient(
  176deg,
  rgba(49, 95, 113, 1) 0%,
  rgba(73, 201, 193, 1) 100%
);

@if $PLATFORM == "MONDORED" {
  $white1: #f9f3f3;
  $white2: #f8ecec;
  $white3: #e1e1e1;
  $primary: #b02832;
  $primary1: #7a0505;
  $primary2: #270609;
  $primary3: #370a0e;
  $primary-pale: #7a3e3e;
  $primary-pale1: #5e1b20;
  $primary-pale2: #615555;
  $primary-pale3: #d9d9d9;
  $best-selling-card1: #7a3e3e;
  $best-selling-card2: #b02832;
  $best-selling-card3: #7a0505;
  $section-button: #5e1b20;
  $progress-bar-bg: #7a3e3e;
  $progress-bar-text: #f9f3f3;
  $progress-bar-stroke: #615555;
  $radar-fill: #7a3e3e;
  $charts-fill: #af5959;
  $area-charts-curve: #5e1b20;
  $weak-bar: #af5959;
  $foreigners-chart: #af5959;
  $proba-chart-real: #370a0e;
  $proba-chart-selected: #af5959;
  $slider-rail: #5e1b20;
  $subscribed-box: #7a050530;
  $full-gauge: #5e1b20;
  $right-omi: #270609;
  $right-home: #370a0e;
  $right-thumb: #370a0e;
  $right-side-title: #fff7fa;
  $pdf-button: #7a0505;
  $checkbox-button: #370a0e;
  $compared-stop-color: #d9d9d9;
  $compared-icon-color: #7a0505;
  $recap-text: #7a3e3e;
  $prob-sale-bg: #fff3f3;
  $nav-hover: #d9d9d9;
  $radio-btn-text: #7a0505;
  $nav-bg: #7a3e3e;
  $nav-active-bg: #5e1b20;
  $radio-btn-bg: #e1e1e1;
  $auth-gradient: linear-gradient(
    176deg,
    rgba(122, 5, 5, 1) 0%,
    rgba(176, 40, 50, 1) 100%
  );
}

@if $PLATFORM == "BAKECA" {
  $white: #f1ffe0;
  $white1: #f1ffe0;
  $white2: #fcfff7;
  $white3: #fcfff7;
  $primary: #80ba27;
  $primary1: #80ba27;
  $primary2: #0f172a;
  $primary3: #0f172a;
  $primary-pale: #80ba27;
  $primary-pale1: #80ba27;
  $primary-pale2: #8e998c;
  $primary-pale3: #65a30d29;
  $charts-fill: #bde383;
  $weak-bar: #bde383;
  $foreigners-chart: #80ba27;
  $proba-chart-selected: #bde383;
  $section-button: #65a30d;
  $area-charts-curve: #80ba27;
  $slider-rail: #65a30d;
  $subscribed-box: #567a1f;
  $progress-bar-bg: #bde383;
  $progress-bar-text: #0f172a;
  $progress-bar-stroke: #80ba27;
  $best-selling-card1: #bde383;
  $best-selling-card2: #80ba27;
  $best-selling-card3: #567a1f;
  $full-gauge: #80ba27;
  $radar-fill: #bde383;
  $proba-chart-real: #80ba27;
  $right-omi: #f1ffe0;
  $right-home: #0f172a;
  $right-thumb: #65a30d;
  $right-side-title: #65a30d;
  $pdf-button: #65a30d;
  $checkbox-button: #567a1f;
  $compared-stop-color: #bde383;
  $compared-icon-color: #80ba27;
  $radio-btn-text: #0f172a;
  $radio-btn-bg: #bde383;
  $nav-hover: #fcfff7;
  $nav-bg: #65a30d;
  $nav-active-bg: #80ba27;
  $recap-text: #65a30d;
  $violet: #f98a1a;
  $prob-sale-bg: #fcfff7;
  $auth-gradient: linear-gradient(176deg, #65a30d 0%, #80ba27 100%);
}
