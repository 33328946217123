@import "../../../../../styles/index.scss";

.options-cell {
  color: inherit;
  text-align: left;
  word-break: break-word;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: inherit;

  .actions__wrapper {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    padding: 10px;

    & > button {
      color: $primary3 !important;
    }

    & > button:hover:after {
      z-index: 110;
      position: absolute;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;
      padding: 8px;
      bottom: -40px;
      background-color: #ededed;
      color: $primary3;
      font-size: 11px;
      border-radius: 4px;
      font-weight: 500;
    }

    & > button:first-child {
      margin-right: 5px;
      position: relative;

      &:hover::after {
        content: "Options";
        width: 50px;
        left: 40%;
        transform: translateX(-50%);
      }
    }

    & > button:nth-child(2) {
      position: relative;

      &:hover::after {
        content: "Reset options";
        width: 80px;
        right: 0;
      }
    }
  }
}
