@import "../../../../styles/index.scss";

.packages {
  background-color: $white;
  height: 250px;
  border-radius: 20px;
  padding: 20px;
  -webkit-flex: 1;
  flex: 1;
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-direction: column;
  -webkit-flex-direction: column;

  &:nth-child(2) {
    @media (max-width: 560px) {
      grid-row-start: 2;
      -webkit-grid-row-start: 2;
    }
  }
}

.subcription {
  background-color: $primary1;
  height: 250px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-direction: column;
  -webkit-flex-direction: column;
  grid-column: 1/6;
  -webkit-grid-column: 1/6;

  @media (max-width: 1000px) {
    grid-column: 1/7;
    -webkit-grid-column: 1/7;
  }

  .head_sub {
    color: #ffffff;
  }

  &__header {
    color: #ffffff;
  }

  &__offer {
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
  }

  button {
    max-width: 300px;

    @media (max-width: 950px) {
      height: 50px !important;
    }
  }
}

.subscribed {
  grid-column: 1/6;
  -webkit-grid-column: 1/6;
  background-color: $subscribed-box;
  border-radius: 20px;
  padding: 20px;
  height: 250px;
  flex: 2;
  -webkit-flex: 2;
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-direction: column;
  -webkit-flex-direction: column;
  grid-column: 3/6;
  -webkit-grid-column: 3/6;

  @media (max-width: 1350px) {
    grid-column: 3/7;
    -webkit-grid-column: 3/7;
  }

  @media (max-width: 1000px) {
    grid-row-start: 2;
    grid-column: 1/3;
    -webkit-grid-row-start: 2;
    -webkit-grid-column: 1/3;
    height: 155px;
  }

  @media (max-width: 560px) {
    grid-row-start: 3;
    grid-column: 1/7;
    -webkit-grid-row-start: 3;
    -webkit-grid-column: 1/7;
    height: 155px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }

  button {
    max-width: 250px;

    @media (max-width: 1350px) {
      font-size: 16px !important;
    }
  }
}

.credits {
  background-color: $white;
  color: $primary1;
  padding: 20px 20px;
  height: fit-content;
  border-radius: 15px;
  display: flex;
  display: -webkit-flex;

  p {
    color: $primary3;
    opacity: 1;
    font-size: 26px;
    font-weight: 600;
  }
}

.credits_unsubscribed {
  grid-column-start: 6;
  -webkit-grid-column-start: 6;

  @media (max-width: 1000px) {
    grid-row-start: 2;
    grid-column: 1/1;
    -webkit-grid-row-start: 2;
    -webkit-grid-column: 1/1;
  }
}

.credits_subscribed {
  @media (max-width: 1350px) {
    grid-row-start: 2;
    grid-column-start: 6;
    -webkit-grid-row-start: 2;
    -webkit-grid-column-start: 6;
  }

  @media (max-width: 1000px) {
    grid-row-start: 1;
    grid-column: 5/7;
    -webkit-grid-row-start: 1;
    -webkit-grid-column: 5/7;
  }

  @media (max-width: 980px) {
    grid-row-start: 3;
    grid-column: 1/1;
    -webkit-grid-row-start: 3;
    -webkit-grid-column: 1/1;
  }

  @media (max-width: 560px) {
    grid-row-start: 4;
    -webkit-grid-row-start: 4;
  }
}

.product__price {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 25px;
}
