@import "../../../../styles/index";

.page__container {
  padding: 35px 50px;
  position: relative;
  height: 100%;

  .header__text {
    text-align: center;
    margin-bottom: 40px;
  }

  .subheader__text {
    // color: $primary-pale;
    font-size: 18px;
    font-weight: $font-weight-bold;
    margin-top: 30px;
  }

  .item_list {
    margin: 25px 0;

    .title_list {
      font-weight: $font-weight-bold;
    }

    .values_list {
      padding-left: 20px;
    }
  }

  .description {
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
  }

  .photo_wrapper {
    position: absolute;
    bottom: 10%;
    right: 0;

    img {
      width: 600px;
    }
  }
}
