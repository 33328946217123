@import "../../../styles/index.scss";

.header__container {
  min-height: 80px;
  width: 100%;
  background: $primary;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  padding: 0 20px;
  & > img {
    cursor: pointer;
  }

  @media (min-width: 601px) {
    display: none;
  }
}
