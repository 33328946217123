@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,577;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,577;1,600;1,700;1,800;1,900&display=swap");

html,
body,
#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  // height: 100%;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
}

input {
  border-radius: 4px;
}

#root {
  background-position: right bottom, left top;
  background-repeat: no-repeat;
  justify-content: flex-start;
}

table,
tbody,
thead,
tfoot,
th,
tr,
td {
  font-weight: unset;
}

select,
select:focus-visible,
button {
  outline: none;
  cursor: pointer;
}

button:active,
button:focus,
button:focus:active,
button:active,
button:focus-visible {
  box-shadow: none;
  -webkit-box-shadow: none;
  background-image: none;
  outline: 0;
  border-style: outset;
  border: none;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffff inset !important;
}

input:-webkit-autofill::first-line {
  font-size: 18px !important;
  color: $primary3;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #03434940;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $primary3;
  -webkit-box-shadow: 0 0 1px $primary3;
}
