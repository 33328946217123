@import "../../../../../styles/index.scss";

.copy {
  cursor: pointer;
  height: 14px;
}

.table_data_options {
  color: $primary3;

  a {
    color: $primary3;
  }
}

.empty_table {
  text-align: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  margin: 0 auto;
  max-width: 520px;
  padding: 50px 0;

  @media (max-width: 959.95px) {
    position: relative;
    left: -47%;
  }
}
