@import "../../../../styles/index.scss";

.field__wrapper {
  position: relative;
  margin-bottom: 10px;

  .field__textarea,
  .input-group {
    width: 100%;
    color: $primary3;
    margin-bottom: 10px;
    padding: 2px;
  }

  .input-group {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    border: 1px solid $primary;
    transition: outline 0.05s ease;

    &:hover:not(.error-field) {
      outline: 1px solid $primary;
    }

    &:focus-visible:not(.error-field) {
      outline: 1px solid $primary;
    }

    .number_btns {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 2px;

      button {
        padding: 0 !important;
      }
    }
  }

  .field__input {
    width: 100%;
    border: none;
    color: $primary3;
    font-size: 18px;
    text-align: center;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: #aeaeb2;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .field__label {
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .descNumber {
    font-size: 12px;
    color: gray;
    margin-bottom: 10px;
  }
  .error {
    display: flex;
    display: -webkit-flex;
    color: $red;
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .error-textarea,
  .error-field {
    color: $red;
    border: 1px solid $red;

    &:hover {
      outline: 1px solid $red;
    }

    &:focus-visible {
      outline: 1px solid $red;
    }
  }
}

.full-height {
  height: 100%;

  .field__textarea {
    height: calc(100% - 24px);
  }
}
