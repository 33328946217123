@import "../../../../styles/index";

.first_page_wrapper {
  position: relative;
  height: 100%;
}

.first_page_img {
  height: 100%;
  background-image: url("../../../../assets/images/download-02.webp");
  background-size: cover;
}

.first_page_img_mondored {
  height: 100%;
  background-image: url("../../../../assets/images/download-mondored.webp");
  background-size: cover;
}

.first_page_img_bakeca {
  height: 100%;
  background-color: $primary;
}

.first_page_bakeca_logo {
  position: absolute;
  top: 130px;
  width: 400px;
  left: 50%;
  transform: translate(-50%);
}

.first_page_header {
  height: 250px;
  width: 100%;
  background-color: $white1;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
  z-index: 1000;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 32px;
    font-weight: 500;
    text-transform: uppercase;
    color: $primary2;
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;

    div {
      font-size: 28px;
      font-weight: 500;
      color: $primary2;
    }

    .hr {
      height: 0.1px;
      background-color: $primary2;
    }
  }

  .subtitle {
    font-size: 14px;
    color: $primary-pale2;
  }
}
