@import "../../../../styles/index";

.container-inner {
  padding: 50px 70px;

  @media (max-width: 960px) {
    padding: 50px 20px;
  }

  .title {
    font-size: 48px;
    font-weight: $font-weight-middle;
    text-align: center;
    color: $primary2;
  }

  .description {
    font-size: 20px;
    // max-width: 1080px;
    font-weight: $font-weight-middle;
    text-align: center;
    line-height: 1.2;
    color: $primary-pale2;
  }

  .copy_wrapper {
    display: grid;
    display: -webkit-grid;
    grid-template-columns: 1fr 1fr;
    -webkit-grid-template-columns: 1fr 1fr;
    gap: 20px;
    position: relative;

    @media (max-width: 960px) {
      grid-template-columns: 1fr;
      -webkit-grid-template-columns: 1fr;
    }
  }

  .copy {
    white-space: break-spaces;
    // margin-top: 80px;
    background-color: $white1;
    border-radius: 20px;
    padding: 60px;

    @media (max-width: 960px) {
      width: 75%;
      min-width: 350px;
      margin: 0 auto;

      // &:first-child {
      //   // margin-top: 80px;
      // }
    }

    @media (max-width: 700px) {
      width: 90%;
    }

    .copy__body {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: justify;
      max-height: 400px;
      overflow-y: auto;
      min-height: 300px;
      max-height: 300px;
      padding-right: 20px;
    }

    .copy__footer {
      display: flex;
      display: -webkit-flex;
      align-items: flex-end;
      -webkit-align-items: flex-end;
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
      margin-top: 50px;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;

    button {
      background-color: $section-button !important;
    }
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
