@import "../../../../styles/index.scss";

.signup__wrapper {
  display: grid;
  //grid-template-columns: repeat(2, 1fr);
  grid-template-columns: 0.7fr 1fr;
  -webkit-display: grid;
  -webkit-grid-template-columns: 0.7fr 1fr;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: $auth-gradient;
}

.signup__wrapper-mobile {
  grid-template-columns: repeat(1, 1fr);
  -webkit-grid-template-columns: repeat(1, 1fr);
}

.right_div {
  height: 100%;
  background-repeat: repeat-y;
  background-size: contain;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  justify-content: center;
  -webkit-justify-content: center;
  padding-bottom: 20px;

  .partner {
    margin-left: 30%;
    text-align: center;
  }

  .trust_copy {
    color: white;
    margin-left: 35%;
    margin-top: 24px;
    text-align: center;
  }
}
