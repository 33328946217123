@import "../../../../styles/index";

.area__services__wrapper {
  padding: 35px 50px;

  .header__text {
    text-align: center;
    margin-bottom: 10px;
  }

  .secondary__text {
    font-size: 15px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: center;
    margin-bottom: 40px;
  }

  .area__services__main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(2, 1fr);
    -webkit-grid-column-gap: 30px;
    -webkit-grid-row-gap: 15px;

    .general__card {
      background: $white1;
      border-radius: 30px;
      height: auto;
    }

    .chart_info {
      position: absolute;
      top: 58px;
      font-size: 12px;
      left: 31px;

      b {
        font-size: 13px;
      }
    }

    .pop__change {
      grid-column: 1/3;
    }
  }
}
