@import "../../../../../styles/index.scss";

.table {
  // max-width: 1000px;
}

.copy {
  cursor: pointer;
  height: 14px;
}

.table_data_options {
  color: $primary-pale3;
  display: flex;
  align-items: center;
  gap: 14px;
  // background-color: $primary;
  // padding: 7px 10px;
  // border-radius: 10px;

  &__option {
    cursor: pointer;
    background-color: $white3;
    color: $primary-pale;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: $primary-pale;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.empty_table {
  text-align: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  margin: 0 auto;
  max-width: 520px;
  padding: 50px 0;

  h2 {
    margin-bottom: 20px;
  }

  .create__btn__wrapper {
    display: flex;
    display: -webkit-flex;
    position: relative;
    justify-content: center;
    -webkit-justify-content: center;
    margin-top: 20px;

    button {
      background: $primary !important;
    }

    .create__btn__focusIcon__wrapper {
      color: $primary;
    }

    .create__btn__focusIcon {
      position: absolute;
      right: 30%;
      bottom: -25px;
      width: 40px;
    }
  }

  @media (max-width: 959.95px) {
    position: relative;
    left: -47%;
  }
}
