@import "../../../../styles/index.scss";

.checkbox__wrapper {
  display: flex;

  .checkbox__field {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    margin: 0;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid $checkbox-button;
    background-color: $white;
    appearance: none;
    -webkit-appearance: none;
    display: grid;
    place-content: center;

    &:before {
      content: "✔";
      font-size: 12px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 2em 2em $checkbox-button;
    }

    &:checked {
      &:before {
        transform: scale(1);
      }
    }
  }
}
