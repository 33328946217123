@import "../../../../styles";

.recharts-label-list .recharts-text tspan {
  font-size: 12px;
  font-weight: 600 !important;
  fill: $primary3 !important;
}

.bar-chart-horizontal {
  .recharts-label-list .recharts-text tspan {
    fill: $primary3 !important;
  }

  .recharts-rectangle {
    fill: $primary-pale3;
  }

  .strong-bar {
    fill: $area-charts-curve;
  }

  .weak-bar {
    fill: $weak-bar;
  }
}
