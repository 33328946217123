@import "../../../../styles";

.area__services__wrapper {
  padding: 0px 70px 50px;

  @media (max-width: 840px) {
    padding: 0px 20px 50px;
  }

  .header__text {
    margin-bottom: 40px;

    p {
      font-size: 36px;
      font-weight: $font-weight-middle;
    }
  }

  // .secondary__text {
  //   text-align: center;
  //   font-size: 20px;
  //   font-weight: $font-weight-middle;
  //   color: $primary-pale2;
  //   margin-bottom: 60px;
  // }

  .area__services__main {
    // display: grid;
    // grid-template-areas: "safety_change safety_change safety_change safety_change safety_change safety safety safety safety safety";
    // grid-template-columns: repeat(10, 1fr);
    // grid-column-gap: 30px;
    // grid-row-gap: 15px;
    // -webkit-display: grid;
    // -webkit-grid-template-areas: "safety_change safety_change safety_change safety_change safety_change safety safety safety safety safety";
    // -webkit-grid-template-columns: repeat(10, 1fr);
    // -webkit-grid-column-gap: 30px;
    // -webkit-grid-row-gap: 15px;

    // @media (max-width:1200px) {
    //   display: block;
    // }

    display: block;

    .general__card {
      background: $white1;
      border-radius: 30px;
      height: auto;

      &_container {
        position: relative;

        .chart_info {
          position: absolute;
          top: 76px;
          padding: 0px 30px;
          font-size: 16px;
          line-height: 18px;

          @media (max-width: 1000px) {
            font-size: 12px;
          }

          b {
            font-size: 17px;

            @media (max-width: 1000px) {
              font-size: 13px;
            }
          }
        }
      }

      &:not(:last-child) {
        margin: 20px 0;
      }
      &:last-child {
        margin: 20px 0 0;
      }

      // @media (max-width:1200px) {
      //   &:not(:last-child) {
      //     margin: 20px 0;
      //   }
      //   &:last-child {
      //     margin: 20px 0 0;
      //   }
      // }
    }

    .safety_change {
      grid-area: safety_change;
      -webkit-grid-area: safety_change;
    }

    .safety {
      grid-area: safety;
      -webkit-grid-area: safety;
    }
  }
}
