@import "../../../../../../styles";

.right__side {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  border-radius: 0 30px 30px 0;
  padding: 40px 40px 58px;
  background-color: $right-omi;

  @media (max-width: 390px) {
    padding: 40px 30px 58px;
  }

  .prices__container {
    display: flex;
    align-items: center;
    // gap: 86px;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

    @media (max-width: 460px) {
      flex-direction: column;
      gap: 10px;
    }

    // @media (max-width: 1000px) {
    //   gap: 65px;
    // }

    // @media (max-width: 840px) {
    //   gap: 13vw;
    // }

    // @media (max-width: 480px) {
    //   gap: 8vw;
    // }

    .price__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      background-color: $primary-pale;
      padding: 20px 35px;
      border-radius: 10px;
      // border: 1px solid white;

      @media (max-width: 560px) {
        padding: 20px 28px;
      }

      @media (max-width: 460px) {
        width: 100%;
        gap: 10px;
      }

      &:first-child {
        @media (min-width: 461px) {
          margin-right: -18px;
        }
      }

      &:last-child {
        @media (min-width: 461px) {
          margin-left: -18px;
        }
      }

      .subheader_text {
        color: $light-gray;
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
        border-bottom: 1px solid white;
        padding-bottom: 5px;
        // margin-bottom: 5px;
        // text-transform: uppercase;

        @media (max-width: 485px) {
          font-size: 1.1rem;
        }

        @media (max-width: 460px) {
          margin-bottom: 10px;
        }
      }

      .text {
        color: $light-gray;
        font-size: 0.8rem;
        font-weight: $font-weight-normal;
        text-align: center;
        margin-bottom: 10px;

        @media (max-width: 485px) {
          font-size: 0.7rem;
        }
      }

      .price {
        color: $white;
        font-size: 1.3rem;
        font-weight: $font-weight-bold;
        text-align: center;

        @media (max-width: 485px) {
          font-size: 1.2rem;
        }

        .euro {
          color: inherit;
          font-size: 1rem;
          font-weight: inherit;
          text-align: inherit;

          @media (max-width: 485px) {
            font-size: 0.9rem;
          }
        }
      }
    }

    .avg__container {
      background-color: $section-button;

      @media (min-width: 461px) {
        padding: 25px 35px;
        gap: 35px;
        z-index: 2;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        // border: 1px solid white;

        @media (max-width: 560px) {
          padding: 25px 28px;
        }

        .subheader_text {
          font-size: 1.4rem;
          font-weight: 500;

          @media (max-width: 485px) {
            font-size: 1.3rem;
          }
        }

        .text {
          font-size: 0.9rem;
          font-weight: 500;

          @media (max-width: 485px) {
            font-size: 0.8rem;
          }
        }

        .price {
          font-size: 1.5rem;
          font-weight: 700;

          @media (max-width: 485px) {
            font-size: 1.3rem;
          }

          .euro {
            font-size: 1.3rem;

            @media (max-width: 485px) {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }

  .photo__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 35px;

    @media (max-width: 900px) {
      height: 30px;
    }

    @media (max-width: 435px) {
      height: 28px;
    }

    // @media (max-width: 390px) {
    //   height: 25px;
    // }

    .slider_rail {
      width: 100%;
      height: 100%;
      color: $slider-rail;
      display: flex;
      align-items: center;

      svg {
        width: 100%;
      }
    }

    .slider_track {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      align-items: center;
      height: 35px;

      @media (max-width: 900px) {
        height: 30px;
      }

      @media (max-width: 435px) {
        height: 28px;
      }

      &_track {
        color: $primary-pale;
        display: flex;
        align-items: center;
        height: 100%;

        @media (min-width: 840px) and (max-width: 1100px) {
          width: 30vw;
        }

        @media (max-width: 839px) {
          width: 50vw;
        }

        @media (max-width: 500px) {
          width: 40vw;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .slider_home,
    .slider_rent,
    .slider_home_rail {
      position: absolute;
      height: 48px;
      width: 48px;
      color: $right-home;

      @media (max-width: 545px) {
        height: 42px;
        width: 42px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .slider_left_thumb {
      position: absolute;
      left: 0;
      width: 30px;
      height: 48px;
      color: $right-thumb;

      @media (max-width: 545px) {
        width: 27px;
        height: 42px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .slider_right_thumb {
      position: absolute;
      right: 0;
      width: 30px;
      height: 48px;
      color: $right-thumb;

      @media (max-width: 545px) {
        width: 27px;
        height: 42px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .slider_min {
      position: absolute;
      left: 3px;
      bottom: -26px;
      color: $white;
      font-size: 14px;
      font-weight: 500;
    }

    .slider_max {
      position: absolute;
      right: 3px;
      bottom: -26px;
      color: $white;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .header_text {
    color: $right-side-title;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 42px;

    sup {
      font-size: 16px;
    }
  }

  .photo__caption {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    justify-content: space-evenly;
    -webkit-justify-content: space-evenly;
    gap: 10px;
    margin-top: 20px;
  }
}

.right__side__mbl {
  border-radius: 0 0 30px 30px;

  .text {
    font-size: 0.5rem;
  }

  .price {
    font-size: 1rem;
  }
}

sup {
  font-size: 0.9rem;
}
