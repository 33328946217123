@import "../../../../styles/index.scss";

.secondary__btn {
  background: transparent;
  border-radius: 10px;
  height: 50px;
  margin: 0;
  font-size: 18px;
  font-weight: $font-weight-bold;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  color: $primary;
  gap: 5px;

  span {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  & > img {
    // margin-right: 5px;
    width: 22px;
    height: 22px;
  }
}
