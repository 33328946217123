.pagination {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;

  .arrow__back,
  .arrow__next {
    background-color: transparent;
    border: none;
    padding: 5px;
  }

  .arrow__next {
    svg {
      transform: rotate(180deg);
    }
  }

  .text {
    display: inline-block;
    margin: 0 10px;
    font-weight: bold;
    font-size: 13px;
  }

  .btn__disabled {
    cursor: not-allowed;

    svg {
      path {
        stroke: #51676C;
      }
    }
  }
}
