@import "../../../../styles/index";

.omi__data__wrapper {
  padding: 50px 70px;

  @media (max-width: 960px) {
    padding: 50px 20px;
  }

  .omi__data__title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    color: $primary2;
    margin-bottom: 10px;
  }

  .omi__data__secondary_text {
    font-size: 20px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 60px;
  }

  .omi__data__main {
    display: grid;
    display: -webkit-grid;
    grid-template-columns: 0.5fr 1fr;
    -webkit-grid-template-columns: 0.5fr 1fr;
  }
}

.omi__data__mbl {
  .omi__data__main {
    display: block;
  }
}
