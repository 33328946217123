@import "../../../styles/index.scss";

.report-modal__content {
  max-width: 440px;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  gap: 20px;

  h1 {
    line-height: 1;
  }

  p {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .report-title__content {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  .user-credits {
    background: $white;
    border: 2px solid $primary;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    padding: 15px;
    margin: 25px 0 30px;
    width: 100%;
    max-width: 320px;

    & > img {
      width: 45px;
      height: 45px;
      margin-right: 25px;
      display: block;
    }

    .credits__text {
      width: 100%;

      & > div {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        padding: 8px;

        .credits__value {
          display: inline-block;
          margin-left: 20px;
          min-width: 50px;
          text-align: right;
        }
      }

      .text__line {
        border-bottom: 1px solid rgba(3, 67, 73, 0.25);
      }
    }
  }

  .download-file {
    text-decoration: none;
    color: $primary;
    font-weight: 500;
    font-size: 20px;
    margin-top: 10px;
  }

  .download-file:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $primary1;
    font-weight: 500;
    font-size: 20px;
    margin-top: 10px;
  }

  .carica_file_error {
    color: $rosso;
  }

  .carica_file_error {
    color: $primary1;
  }

  .carica_file_button {
    background-color: #078ea6;
    display: flex;
    display: -webkit-flex;
    color: #ffffff;
    height: 45px;
    width: max-content;
    font-size: 20px;
    padding: 15px;
    border-radius: 10px;
    align-items: center;
    -webkit-align-items: center;
    font-weight: $font-weight-bold;
  }

  .carica_file_button:hover {
    cursor: pointer;
  }

  .carica_file {
    width: auto;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    height: auto;
  }

  .carica_file_name {
  }

  .modal__actions {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    text-align: center;

    & > button:first-child {
      margin-bottom: 15px;
    }
  }
}
