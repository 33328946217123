@import "../../../../styles";

.pyramid-chart {
  .recharts-tooltip-cursor {
    fill: $primary-pale3 !important;
  }

  .strong-bar {
    fill: $area-charts-curve;
  }

  .weak-bar {
    fill: $weak-bar;
  }
}
