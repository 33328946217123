@import "../../../../styles/index";

.comparison__wrapper {
  padding: 50px 70px;

  @media (max-width: 960px) {
    padding: 50px 20px;
  }

  .no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: $primary2;
    background-color: $white1;
    padding: 40px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;

    @media (max-width: 840px) {
      width: 100%;
    }
  }

  .comparison__title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    color: $primary2;
    margin-bottom: 10px;
  }

  .comparison__secondary_text {
    font-size: 20px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: center;
    margin-bottom: 60px;
  }

  .comparison__type {
    display: flex;
    align-items: center;
    justify-content: center;

    &_item {
      height: 50px;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      border: 2px solid $primary2;
      cursor: pointer;
    }

    &_buy {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: none;
    }

    &_rent {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .active {
      background-color: $primary2;
      color: #fff;
    }
  }

  .comparison__action-mobile {
    margin-top: 30px;
  }

  .comparison__table {
    margin-bottom: 20px;
    width: 100%;
    overflow-x: auto;

    .table {
      width: 100%;
      background-color: $white1;
      border-radius: 30px;
      border-spacing: 0px !important;
      border: 1px solid #daebee;
      margin-top: 30px;
      min-width: 650px;

      thead {
        background-color: $primary-pale;
        color: $white1;

        th {
          font-size: 18px;
          font-weight: 600;
          padding: 15px;
          line-height: 1.1;
        }

        th:not(:last-child) {
          border-right: 1px solid #daebee;
        }
      }

      tbody {
        tr {
          text-align: center;
          height: 70px;

          td {
            font-size: 14px;
            border-bottom: 1px solid #daebee;
            padding: 0 15px;
          }

          td:first-child {
            padding: 0px;

            img {
              width: 100%;
              height: 100px;
              object-fit: cover;
              vertical-align: top;
            }
          }

          .price_column {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: inherit;

            b {
              font-size: inherit;
            }

            img {
              width: 12px;
            }
          }

          .flex_column {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
        }
      }

      tr:last-child > td {
        border-bottom: none;
      }
    }
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    margin-bottom: 10px;

    &__text {
      font-size: 14px;

      img {
        width: 12px;
      }
    }
  }
}

.comparison__mbl {
  .comparison__main {
    display: block;
  }
}

.arrow_img {
  transform: rotateX(180deg);
}
