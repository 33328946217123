@import "../../../../styles/index.scss";

.container__inner {
  display: grid;
  //grid-template-columns: repeat(2, 1fr);
  grid-template-columns: 0.7fr 1fr;
  -webkit-display: grid;
  -webkit-grid-template-columns: 0.7fr 1fr;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: $auth-gradient;

  .inner__link {
    text-align: center;

    margin-top: 20px;
    //padding-bottom: 12.5%;

    .link {
      text-decoration: underline;
      margin-top: 15px;
      font-weight: $font-weight-bold;
      font-size: 18px;
      text-align: center;
      color: $primary3;
      font-weight: 600;
    }

    p {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .login__container {
    color: $primary3;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    height: 100%;
    padding: 10% 20px 75px;

    .mondoredLogo {
      width: 200px;
      margin-bottom: 40px;
    }

    .logo {
      display: flex;
      display: -webkit-flex;
      width: 145px;
      height: 56px;
      margin-bottom: 40px;
      cursor: pointer;
    }

    .login-form__image {
      // width: 100%;
      max-width: 290px;
      text-align: start;

      @media (max-width: 840px) {
        text-align: center;
      }

      img {
        width: 100%;
      }
    }

    .login-form__wrapper {
      width: 100%;
      max-width: 375px;
      padding-top: 45px;

      .login__form {
        margin-top: 40px;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        width: 100%;

        & > div:not(:last-of-type) {
          margin-bottom: 30px;
        }
      }
    }

    .forgot-pwd__link {
      color: $primary3;
      font-size: 13px;
      text-align: right;
      cursor: pointer;
      margin-bottom: 40px;
    }

    .errorMsg {
      display: flex;
      display: -webkit-flex;
      color: $red;
      font-size: 12px;
      line-height: 1.2;
    }
  }
}

.container__inner-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  -webkit-display: grid;
  -webkit-grid-template-columns: repeat(1, 1fr);
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  .login__container-mobile {
    padding: 46px 20px 75px;
  }
}

.right_div {
  height: 100%;
  background-repeat: repeat-y;
  background-size: contain;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  justify-content: center;
  -webkit-justify-content: center;
  padding-bottom: 20px;

  .partner {
    margin-left: 30%;
    text-align: center;
  }

  .trust_copy {
    color: white;
    margin-left: 35%;
    margin-top: 24px;
    text-align: center;
  }
}
