.submit-btn {
  margin-top: auto;
  width: 100%;

  &>button>img {
    height: 20px !important;
  }

  .error__msg {
    margin-bottom: 15px;
    font-size: 14px;
    text-align: center;
    color: #EB5757;
  }
}