@import "../../../../../../styles/index";

.common__card__wrapper {
  padding: 15px 25px 30px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  height: 100%;
  // min-height: 120px;

  @media (max-width: 1000px) {
    gap: 10px;
    min-height: 105px;
  }

  .first__row {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    gap: 10px;

    .header {
      @media (max-width: 840px) {
        max-width: 160px;
      }
      // font-size: 14px;
      // font-weight: $font-weight-middle;
      // color: $gray;
    }
  }

  .content {
    font-size: 30px;
    font-weight: $font-weight-bolder;
    color: $primary2;
    margin-top: 10px;
    margin-bottom: 10px;
    // margin: 20px 0px 10px 0px;

    @media (min-width: 840px) and (max-width: 950px) {
      font-size: 26px;
    }

    sup {
      font-size: 16px;
    }
  }

  .chart__source {
    font-size: 14px;
    text-align: end;
    position: absolute;
    bottom: 10px;
    right: 30px;
  }
}
