@import "../../../../../../styles";

.left__side {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 30px 0 0 30px;
  padding: 40px;
  background-color: $primary-pale;

  @media (max-width: 840px) {
    padding: 30px;
    border-radius: 30px 30px 0 0;
    grid-gap: 20px;
  }

  .item {
    text-align: center;
    .title {
      font-size: 0.8rem;
      font-weight: $font-weight-normal;
      color: $white;
    }

    .text {
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      color: $white;
      margin-top: 10px;
    }
  }
}
