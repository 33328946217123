@import "../../../../styles/index.scss";
.assessment__wrapper {
  padding: 70px 70px 50px;

  @media (max-width: 840px) {
    padding: 70px 20px 50px;
  }

  .assessment__title {
    font-size: 48px;
    text-align: center;
    // margin-bottom: 60px;
    font-weight: $font-weight-middle;
    color: $primary2;
    margin-bottom: 20px;
    position: relative;
    width: fit-content;
    margin: 0 auto 60px;

    @media (max-width: 500px) {
      margin-bottom: 40px;
    }
  }

  .assessment {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;

    &__result {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 30px 40px;
      border-radius: 30px;
      background-color: #f4f7f8;

      @media (max-width: 500px) {
        flex-direction: column;
        padding: 50px 40px 30px;
      }

      &__price {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        .value {
          font-weight: 600;
          font-size: 42px;
        }

        .probability {
          font-size: 20px;
          text-align: center;

          b {
            font-size: inherit;
          }
        }
      }

      &__probability {
        position: relative;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
      }
    }

    &__text {
      font-size: 20px;
      font-weight: 500;
      color: $primary-pale2;
      text-align: justify;
      line-height: 1.2;
    }
  }
}
