@import "../../../../styles/index";

.area__services__wrapper {
  padding: 35px 50px;

  .header__text {
    margin-bottom: 40px;
  }

  .agency_info {
    display: flex;
    gap: 40px;

    img {
      width: 47%;
      border-radius: 10px;
      object-fit: contain;
    }

    &_text {
      display: flex;
      flex-direction: column;
      gap: 30px;

      p {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;
      }
    }
  }
}
