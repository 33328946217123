@import "../../styles/index.scss";

.navbar {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
  padding: 30px;

  .logo__wrapper {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    margin-right: 40px;

    @media (max-width: 930px) {
      margin-right: 30px;
    }

    @media (max-width: 860px) {
      margin-right: 20px;
    }

    // @media (max-width: 600px) {
    //   align-self: flex-start;
    // }

    .logo {
      width: 100%;
      max-width: 200px;
      object-fit: contain;

      // @media (max-width: 420px) {
      //   min-width: 200px;
      // }
    }
  }
}

.navbar__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media (max-width: 650px) {
    gap: 20px;
  }

  @media (max-width: 600px) {
    button {
      display: none;
    }
  }
}

.user-info {
  // width: 100%;
  min-height: 50px;
  // min-width: 180px;
  background-color: $white;
  padding: 0 15px;
  flex-direction: column;
  -webkit-flex-direction: column;
  border-radius: 10px;
  border: 1px solid $primary;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin: 0 !important;
  // width: fit-content !important;
  position: relative !important;

  @media (min-width: 701px) {
    min-width: 180px;
  }

  .user__details {
    width: 100%;
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    color: $primary !important;

    .user__image {
      height: 30px;
      width: 30px;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid $primary;
    }

    p {
      margin-left: 10px;
      font-weight: 600;
      text-transform: capitalize;
      font-size: 13px;
      color: $primary;

      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  .dropdown__menu {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 100%;
    z-index: 1000;
    position: absolute;
    top: 66px;
    border-radius: 10px;
    border: 1px solid $primary;
    overflow: hidden;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    @media (max-width: 750px) {
      width: 250px;
      right: 0;
    }

    .profile_btn {
      color: $primary !important;
    }
  }

  .menu__arrow {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 15px;
    transform: rotate(-90deg);
  }

  .arrow__up {
    transform: rotate(90deg);
  }
}

.dropdown_item {
  width: 100%;
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  height: 50px;
  gap: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid $white3;
  }

  &:hover {
    background-color: $primary;
    color: #ffff;
  }

  svg {
    height: 28px;
    width: 28px;
  }
}

.logout {
  color: #fd7072;
  border-bottom: none !important;

  &:hover {
    background-color: #fd7072;
    color: #ffff;
  }
}

.active_item {
  background-color: $primary !important;
  color: #ffff;

  & > svg {
    path {
      fill: $white;
    }
  }
}

.credits {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  padding: 15px;
  background-color: $primary3;
  color: #fff;
  width: 100%;
  height: 50px;

  svg {
    height: 30px;
    width: 30px;
  }

  p {
    margin-left: 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 13px;
    color: #fff;
  }
}

.demo_mbl_btn {
  margin: 0 auto 20px;

  @media (min-width: 601px) {
    display: none;
  }
}
