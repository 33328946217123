@import "../../../../../../styles/index";

.best__selling__card {
  padding: 30px 40px;
  background-color: $white1;
  border-radius: 30px;
  // height: 300px;

  .first__row {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    margin-bottom: 20px;

    .icon__wrapper {
      margin-right: 10px;
      padding: 4px;
      border-radius: 4px;
      //background-color: $primary-pale3;
      width: 20px;
      height: 26.5px;
    }

    .icon_two_months {
      color: $best-selling-card1;
    }

    .icon_six_months {
      color: $best-selling-card2;
    }

    .icon_twelve_months {
      color: $best-selling-card3;
    }

    .first__row__text {
      font-size: 14px;
      font-weight: $font-weight-middle;
      color: $gray;
    }
  }

  .second_row_test {
    color: #9a9a9a;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .second_row_price {
    font-size: 24px;

    @media (max-width: 1250px) {
      font-size: 22px;
    }
  }

  .period {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: $font-weight-middle;
    color: $gray;
  }

  .price {
    font-size: 44px;
    font-weight: $font-weight-middle;

    @media (max-width: 1250px) {
      font-size: 42px;
    }

    span {
      font-size: 24px;
      font-weight: inherit;
      color: inherit;
    }
  }
}

.percent_box_positive {
  margin-left: 30px;
  padding: 7px 10px;
  background-color: #dbf6e5;
  border-radius: 30px;
  font-size: 16px;
  color: #628168;
}

.percent_box_negative {
  margin-left: 30px;
  padding: 7px 10px;
  background-color: #f6dbdb;
  border-radius: 30px;
  font-size: 16px;
  color: #9c3b3b;
}
