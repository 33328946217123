@import "../../../../styles/index.scss";

.subscription {
  grid-column: 1/6;
  -webkit-grid-column: 1/6;
  background-color: $subscribed-box;
  border-radius: 20px;
  padding: 20px;
  min-height: 250px;
  flex: 2;
  -webkit-flex: 2;
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-direction: column;
  -webkit-flex-direction: column;
  gap: 20px;

  @media (max-width: 1000px) {
    grid-column: 1/7;
    -webkit-grid-column: 1/7;
  }

  h3 {
    margin-bottom: 10px;
  }

  button {
    width: 250px;

    @media (max-width: 1350px) {
      font-size: 16px !important;
    }
  }

  .packages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    @media (max-width: 1260px) {
      flex-direction: column;
    }

    .type {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @media (max-width: 730px) {
        flex-direction: column;
      }
    }

    .package {
      background-color: white;
      height: 350px;
      border-radius: 20px;
      padding: 20px;
      -webkit-flex: 1;
      flex: 1;
      display: flex;
      display: -webkit-flex;
      align-items: flex-start;
      -webkit-align-items: flex-start;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      flex-direction: column;
      -webkit-flex-direction: column;
      gap: 20px;

      @media (max-width: 750px) {
        height: 300px;
      }

      // @media (max-width: 680px) {
      //   height: 250px;
      // }

      .title {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: inherit;

        h3 {
          color: inherit;
        }
        p {
          color: inherit;
          line-height: 1.1;
        }
      }

      .cta {
        display: flex;
        flex-direction: column;
        gap: 25px;
        color: inherit;

        p {
          color: inherit;
        }
      }

      .product__price {
        font-size: 28px;
        font-weight: 700;
        color: inherit;
        // margin-bottom: 25px;
      }
    }
  }
}

.credits {
  background-color: white;
  color: $primary;
  padding: 20px 20px;
  height: fit-content;
  border-radius: 15px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  gap: 10px;

  span {
    font-size: 16px;
  }

  p {
    color: $primary3;
    opacity: 1;
    font-size: 26px;
    font-weight: 600;
  }
}
