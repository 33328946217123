@import "../../../styles/index.scss";

.multiselect {
  .label_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .field__label {
    font-weight: 600;
    font-size: 22px;
  }

  .error {
    display: flex;
    color: $red;
    font-size: 13px;
    line-height: 1.2;
    margin-top: 5px;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none; /* for Safari and Chrome */
    background-color: #fff;
    border: 1px solid $primary;
    width: 18px;
    height: 18px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
  }

  input[type="checkbox"]:checked::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-color: $primary;
  }
}
