@import "../../../../styles/index.scss";

.stepper {
  width: 100%;
  max-width: 400px;
  // max-height: 700px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  @media (max-width: 1000px) {
    display: none;
  }

  &__wrapper {
    width: 100%;
    max-width: 400px;
    // max-height: 700px;
    height: calc(100% - 40px);
    top: calc(0px + 20px);
    position: fixed;
    background-color: $white1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 9vh;
    padding: 40px 60px;

    @media (max-width: 1000px) {
      max-width: 300px;
    }

    .logo {
      width: 250px;

      img {
        width: 100%;
      }
    }

    .steps {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      align-self: flex-start;
      height: 400px;

      &__step {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        z-index: 1;
        width: 100%;
        gap: 40px;

        .number {
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          background-color: #e4e4e4;
          color: #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s;
        }

        .text {
          color: #e4e4e4;
          transition: color 0.3s;
          font-weight: 500;
        }
      }

      .active {
        .number {
          background-color: $primary;
        }

        .text {
          color: $primary;
        }
      }

      &__line {
        width: 2px;
        height: 100%;
        background-color: #e4e4e4;
        position: absolute;
        top: 0;
        left: 19px;
        // left: 50%;
        // transform: translateX(-50%);

        &__fill {
          position: absolute;
          top: 0;
          width: 2px;
          height: 0;
          background-color: $primary;
          transition: height 0.3s;
        }
      }
    }
  }
}
