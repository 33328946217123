@import "../../styles/index.scss";

.create_report {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow: auto;
  height: 100vh;

  &__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    .logo {
      width: 200px;
      margin-bottom: 20px;

      img {
        width: 100%;
      }

      @media (min-width: 921px) {
        display: none;
      }
    }

    &__header {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      align-items: center;
      -webkit-align-items: center;
      max-width: 500px;
      margin-bottom: 40px;

      & > h2 {
        text-align: center;
        font-weight: 700;
      }
    }

    &__formik {
      width: 100%;
      display: flex;
      justify-content: center;

      @media (max-width: 920px) {
        padding-bottom: 40px;
      }

      .create-report__form {
        width: 100%;
        max-width: 500px;
        height: 100%;

        .field__wrapper {
          margin-bottom: 45px;

          label {
            font-weight: 600;
            font-size: 22px;
          }
        }

        .form__map-step {
          display: flex;
          display: -webkit-flex;
          flex-direction: column;
          -webkit-flex-direction: column;

          .map_icon {
            color: $primary;

            svg {
              width: 40px;
              height: 40px;
            }
          }
        }

        .radio-group__wrapper {
          margin-bottom: 45px;

          .radio-group__label {
            font-weight: 600;
            font-size: 22px;
            margin-bottom: 10px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .radio-group {
            display: grid;
            gap: 20px;
            -webkit-display: grid;
            -webkit-gap: 20px;

            @media (min-width: 450px) {
              grid-template-columns: 1fr 1fr;
              -webkit-grid-template-columns: 1fr 1fr;
            }
          }

          .error {
            margin-top: 5px;
          }
        }

        .error {
          display: flex;
          display: -webkit-flex;
          color: $red;
          font-size: 13px;
          line-height: 1.2;
          margin-bottom: 10px;
        }
      }
    }
  }

  .optional_select {
    position: relative;
    text-align: right;
    top: 36px;
    color: rgb(163, 163, 163);
    font-size: 14px;
  }

  .submit_btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    color: $primary;
    margin-top: 40px;
    margin-bottom: 40px;

    svg {
      width: 18px;
      height: 18px;
      margin-bottom: 3px;
    }

    &__back {
      border: 1.5px solid $primary !important;
    }
  }
}
