@import "../../../../styles/modules/colors.scss";

.container {
  padding: 20px 70px;
  background-color: $white1;
  padding-bottom: 80px;
  position: relative;

  @media (max-width: 450px) {
    padding: 20px 20px 70px;
  }
}
