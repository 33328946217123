@import "../../../../styles/index";

.section__wrapper {
  padding: 35px 50px;

  .header__text {
    text-align: center;
    margin-bottom: 40px;
  }

  .gallery__list {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    gap: 80px;
    position: relative;
    align-items: center;

    .list-item__wrapper {
      height: 230px;
      position: relative;
      border-radius: 10px;

      .list__img {
        // width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
        max-width: 100%;
      }

      b {
        position: absolute;
        bottom: 0;
        right: 0;
        background: $primary3;
        color: white;
        padding: 5px 8px;
        font-size: 14px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
