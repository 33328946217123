#second_page {
  .leaflet-container {
    height: 240px;
    width: 100%;
    border-radius: 10px;
  }

  .svg-icon-leaflet {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .leaflet-control-attribution {
    display: none;
  }
}
