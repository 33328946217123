@import "../../../../styles/index.scss";

.contact_us_modal {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px;

  .form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    h3 {
      margin-bottom: 20px;
    }
  }

  .close_btn {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
    color: $primary3;
    cursor: pointer;

    @media (max-width: 600px) {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
