@import "../../../../styles/index.scss";

.header__progress-bar {
  position: relative;
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-top: 40px;
  background-color: #eee;
  width: 100%;
  max-width: 500px;
  height: 15px;
  border-radius: 10px;
  background-color: rgba(94, 35, 157, 0.2);
  //background-color: #eee;
  //opacity: 0.5;

  @media (min-width: 1001px) {
    display: none;
  }

  @media (max-width: 680px) {
    width: 80%;
  }

  .header__progress {
    display: block;
    height: 100%;
    background-color: $violet;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    transition: width 0.5s ease;
  }

  .header__progress-icon {
    position: absolute;
    left: -40px;
    display: flex;
    display: -webkit-flex;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    align-items: center;
    -webkit-align-items: center;
    background-color: $violet;
    color: #ffff;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
  }

  .header__progress-number {
    position: absolute;
    right: -40px;
    display: flex;
    display: -webkit-flex;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: 600;
    align-items: center;
    -webkit-align-items: center;
    background-color: $violet;
    color: #ffff;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
  }
}
