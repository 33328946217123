@import "../../../../../../styles/index";

.chart__wrapper {
  background-color: $white1 !important;

  .chart__titles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    -webkit-display: grid;
    -webkit-grid-template-columns: repeat(3, 1fr);
    -webkit-grid-gap: 15px;
    max-width: 880px;
    margin-top: 20px;
    margin-bottom: 10px;

    .title__item {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;

      .title__color {
        height: 20px;
        width: 20px;
      }

      .title__text {
        margin-left: 10px;
        font-size: 15px;
        color: $primary2;
        font-weight: $font-weight-middle;
      }
    }
  }

  .chart__box {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
    @media (max-width: 910px) {
      overflow-x: scroll;
    }

    .chart {
      width: 100%;
      z-index: 2;
      position: relative;

      .chart__lines {
        position: absolute;
        height: 100%;
        width: 100%;
        //display: flex;
        display: none;
        flex-direction: row;
        -webkit-flex-direction: row;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding: 20px 10px 30px 54px;

        .line {
          background-image: linear-gradient(
            black 33%,
            rgba(255, 255, 255, 0) 0%
          );
          background-position: right;
          background-size: 2px 4px;
          background-repeat: repeat-y;
          height: 100%;
        }

        hr {
          color: $primary2;
          border: 14px dotted $primary2;
          width: 0.1;
        }
      }
    }

    .chart__probabilities {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      width: 3330px;
      margin: 20px 0px 65px;

      .probability__item {
        height: 100%;
        width: 120px;
        position: relative;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;

        .probability__color {
          position: absolute;
          height: 100%;
          width: 2140px;
          margin-right: 100px;
          z-index: 1;
        }

        .probability__text {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          height: 100%;
          width: 100%;
          z-index: 2;
          margin-left: 10px;

          span {
            font-size: 14px;
            color: $black;
          }
        }
      }
    }

    .chart__probabilities__mbl {
      margin: 20px 50px 135px 0px;
      background-color: black;
    }
  }

  .charts {
    position: relative;
    display: grid;
    grid-gap: 70px;
    width: 100%;
  }
}

.within__12__mon {
  background-color: $primary2;
}

.within__6__mon {
  background-color: $primary-pale;
}

.within__2__mon {
  background-color: $primary-pale3;
}
