@import "../../../../../../styles/index";

.actions__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  & > button:first-child {
    margin-top: 10px;
  }
}

.gallery__list {
  width: 100%;
  display: grid;
  display: -webkit-grid;
  grid-template-columns: repeat(4, 1fr);
  -webkit-grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  -webkit-gap: 20px;
  position: relative;

  .list-item__wrapper {
    border-radius: 10px;
    cursor: pointer;
    height: 150px;
    position: relative;

    .cover__image {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .img_span {
      position: absolute;
      bottom: 0px;
      left: 0px;
      background-color: white;
      padding: 5px;
      font-size: 16px;
      font-weight: 600;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 7px;
    }
  }

  .active-span {
    bottom: 5px !important;
    left: 5.6px !important;
  }

  .active-image {
    border: 3px solid $primary;
    padding: 5px;
  }

  .upload-img__btn {
    background-color: $violet;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    cursor: pointer;

    span {
      color: $white;
      font-weight: $font-weight-bolder;
      margin-left: 10px;
    }
  }

  .change-order-btn__wrapper {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

.sort_warning {
  margin-top: 20px;

  &__img_color {
    color: $primary2;

    svg {
      width: 24px;
      vertical-align: middle;
    }
  }

  span {
    font-weight: 600;
    font-size: 14px;
  }
}

.list-item__draggable {
  cursor: grabbing !important;
  position: relative;

  &:hover {
    border: 3px solid $primary;
    padding: 5px;

    .img_overlay {
      transform: scale3d(0.95, 0.94, 0.95);
    }
  }

  .btn__remove {
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .img_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    max-width: 330px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drag_icon {
    width: 34px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.list__img {
  width: 100%;
  height: 100%;
  max-width: 330px;
  // padding: 5px;
  object-fit: cover;
  border-radius: 10px;
}
