@import "../../../../styles";

.custom_tooltip {
  border-radius: 10px;
  background-color: $white;
  padding: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  &__content {
    margin-top: 10px;

    div:first-of-type {
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-bottom: -1.5px;
        background-color: $area-charts-curve;
      }
    }
    div:nth-of-type(2) {
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-bottom: -1.5px;
        background-color: $weak-bar !important;
      }
    }

    .strong_bar {
      background-color: $area-charts-curve !important;
    }

    .weak_bar {
      background-color: $weak-bar !important;
    }
  }
}
