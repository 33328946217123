@import "../../../styles/index.scss";

.select-field-error {
  .css-19s79ba-control,
  .css-1rrneb1-control,
  .css-105jixv-control {
    border: 1px solid $red !important;
    transition: outline 0.05s ease;
    box-shadow: none !important;

    &:hover {
      outline: 1px solid $red !important;
    }
  }
}

.select-field {
  .css-19s79ba-control,
  .css-1rrneb1-control,
  .css-105jixv-control {
    &:hover {
      // outline: 1px solid $primary;
      box-shadow: 0 0 0 1px $primary;
    }
  }
}
