@import "../../../../styles/index";

.gauge__wrapper {
  width: 100%;
  background-color: $white1;
  border-radius: 10px;
  padding: 35px 30px 20px;
  height: 100%;

  @media (max-width: 1000px) {
    min-height: 450px;
  }
}

.chart__wrapper {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  margin-top: 136px;
  position: relative;

  @media (max-width: 1000px) {
    margin-top: 110px;
  }
}

.medium {
  text-transform: uppercase;
  // color: $white1;
  font-size: 36px;
  position: absolute;
  top: 52%;
  font-weight: $font-weight-middle;

  span {
    font-size: 36px;
    position: absolute;
    top: 50%;
    font-weight: $font-weight-middle;
  }
}

.caption {
  // color: $white1;
  font-size: 16px;
  font-weight: $font-weight-bold;
  color: #9d9d9d;
  position: absolute;
  top: 78%;
  text-align: center;
  line-height: 1.2;

  b {
    font-size: 22px;
  }

  span {
    font-size: inherit;
    font-weight: inherit;
  }
}

.gauge_margin_top {
  @media (min-width: 1000px) {
    margin-top: 80px;
  }
}

.source {
  position: absolute !important;
  bottom: 20px !important;
  right: 30px !important;
}
