@import "../../../../styles";

.area-chart {
  .recharts-area-curve {
    stroke: $area-charts-curve;
  }

  .recharts-active-dot {
    circle {
      fill: $area-charts-curve;
    }
  }

  // .recharts-tooltip-cursor {
  //   stroke: $area-charts-curve;
  // }

  stop {
    stop-color: $area-charts-curve !important;
    stroke: $area-charts-curve;
  }
}
