.result_table {
  padding: 20px 0px 40px;
  border-radius: 20px;
}

.credits_input {
  width: 50%;
  min-width: 120px;
  margin: 0 auto;

  /* For WebKit browsers (Chrome, Safari) */
  &__input::-webkit-inner-spin-button,
  &__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For other browsers */
  &__input {
    cursor: pointer;
    width: 100%;
    -moz-appearance: textfield;
  }

  input {
    text-align: center;
  }
}

.report-modal__content {
  max-width: 440px;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  gap: 20px;
}
