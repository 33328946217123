@import "../../../../styles/index.scss";

.report-modal__content {
  max-width: 440px;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;

  h1 {
    margin-bottom: 20px;
  }

  & > p {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
  }

  .user-credits {
    background: $white;
    border: 2px solid $primary;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    padding: 15px;
    margin: 25px 0 30px;
    width: 100%;
    max-width: 320px;

    & > img {
      width: 45px;
      height: 45px;
      margin-right: 25px;
      display: block;
    }

    .credits__text {
      width: 100%;

      & > div {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        padding: 8px;

        .credits__value {
          display: inline-block;
          margin-left: 20px;
          min-width: 50px;
          text-align: right;
        }
      }

      .text__line {
        border-bottom: 1px solid rgba(3, 67, 73, 0.25);
      }
    }
  }

  .modal__actions {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    text-align: center;

    svg {
      width: 30px;
      height: 30px;
    }

    // &>button:first-child {
    //   margin-bottom: 15px;
    // }
  }
}

.error-link {
  color: red;
}
