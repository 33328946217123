@import "../../../../styles/index";

.potential__customer__wrapper {
  // margin-top: 100px;
  // margin-bottom: 50px;
  // padding-left: 70px;
  // padding-right: 70px;

  // @media (max-width: 840px) {
  //   padding-left: 20px;
  //   padding-right: 20px;
  // }
  padding: 50px 70px;

  @media (max-width: 960px) {
    padding: 50px 20px;
  }

  .potential__customer__header {
    font-size: 48px;
    font-weight: $font-weight-middle;
    text-align: center;
    color: $primary2;
    position: relative;
    width: fit-content;
    margin: 0 auto 10px;

    // label {
    //   top: 10px !important;
    //   right: -40px !important;

    //   // @media (max-width: 840px) {
    //   //   position: relative !important;
    //   //   top: auto !important;
    //   //   right: auto !important;
    //   //   width: fit-content !important;
    //   //   margin: 15px auto 0px !important;
    //   // }
    // }
  }

  .content_section {
    background-color: white;
    width: 100%;
    display: grid;
    display: -webkit-grid;
    grid-template-columns: 1fr 1fr 1fr;
    -webkit-grid-template-columns: 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr 1fr;
    margin-top: 100px;

    @media (max-width: 1050px) {
      display: block;
    }
  }

  .best_potential_user_box {
    border-radius: 30px;
    background-color: $white1;
    padding: 28px 50px;
    height: fit-content;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    column-gap: 30px;
    -webkit-column-gap: 30px;
    //margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;

    // @media (max-width: 1650px) {
    //   margin-top: 55px;
    // }

    .best_potential_user_text {
      margin: auto;
      flex-direction: column;
      -webkit-flex-direction: column;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      text-align: center;
    }

    .iconBox {
      // background-color: #beeef3;
      width: 28px;
      height: 25px;
      border-radius: 5px;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;
    }
  }

  .description {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    border-radius: 30px;
    background-color: $white1;
    padding: 28px 40px;
    //width: min(100%, 500px);
    height: fit-content;
    display: flex;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: 1050px) {
      margin-left: 0;
      width: 100%;
    }

    .description__title {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;
    }

    .section__description {
      border: 10px solid #beeef3;
      padding-left: 24px;
      border-width: 0px 0px 0px 11px;

      .section__description__title {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;

        p {
          font-size: 16px;
          font-weight: $font-weight-normal;
          color: $gray;
        }
      }

      .section__description__body {
        margin-top: 10px;

        p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: $font-weight-normal;
          color: $black;
          line-height: 18px;
        }
      }
    }
  }

  .section {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    align-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;

    @media (max-width: 482px) {
      margin-top: -40px;
    }

    &:first-child {
      grid-row: 1/4;
      -webkit-grid-row: 1/4;

      @media (max-width: 1050px) {
        grid-column: 1/3;
        -webkit-grid-column: 1/4;
        grid-row: 1/1;
        -webkit-grid-row: 1/1;
      }
    }

    &:last-child {
      //@media (max-width: 1650px) {
      grid-row: 2/3;
      -webkit-grid-row: 2/3;
      grid-column: 2/4;
      -webkit-grid-column: 2/4;
      flex-direction: row;
      -webkit-flex-direction: row;
      justify-content: center;
      -webkit-justify-content: center;
      align-items: flex-start;
      -webkit-align-items: flex-start;
      //}

      @media (max-width: 1350px) {
        flex-direction: column;
        -webkit-flex-direction: column;
        align-items: center;
        -webkit-align-items: center;
      }

      @media (max-width: 1050px) {
        grid-row: 3/4;
        -webkit-grid-row: 3/4;
      }
    }

    &:nth-child(2) {
      //@media (max-width: 1650px) {
      grid-column: 2/4;
      -webkit-grid-column: 2/4;
      //}

      @media (max-width: 1050px) {
        padding: 60px 0 0;
      }

      @media (max-width: 615px) {
        padding: 0;
      }
    }
  }

  .labels {
    // grid-area: labels;
    // display: flex;
    // flex-direction: column;
    // height: 100%;
    // width: 100%;
    // margin-top: 20px;
    // // background-color: $white1;
    // border-radius: 20px;
    // flex-wrap: nowrap;
    padding-top: 60px;

    @media (max-width: 1650px) {
      padding-bottom: 20px;
    }

    @media (max-width: 615px) {
      padding-top: 20px;
    }

    @media (max-width: 530px) {
      padding-top: 0;
    }

    .labels__item {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      flex-direction: row;
      -webkit-flex-direction: row;
      // padding: 10px 110px 10px 40px;
      border-bottom: 1px solid #e1e6e8;

      p {
        font-size: 14px;
        font-weight: 200;
        color: $primary-pale2;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .potential__customer__description {
    font-size: 20px;
    font-weight: $font-weight-middle;
    text-align: center;
    line-height: 1.2;
    color: $primary-pale2;
  }

  .chart__wrapper {
    display: grid;
    display: -webkit-grid;
    grid-template-areas:
      " space chart chart chart null"
      "space chart chart chart labels"
      "space chart chart chart  _";
    -webkit-grid-template-areas: " space chart chart chart null"
      "space chart chart chart labels" "space chart chart chart  _";
    grid-template-columns: 3fr 1fr 1.4fr 3fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    -webkit-grid-template-columns: 3fr 1fr 1.4fr 3fr 1fr 1fr 1fr 1fr;
    -webkit-grid-template-rows: 1fr 2fr 1fr;
    -webkit-grid-column-gap: 30px;
    -webkit-grid-row-gap: 15px;
    height: 100%;
    width: 100%;

    .chart {
      grid-area: chart;
      -webkit-grid-area: chart;
      height: fit-content;
      width: max-content;
    }
  }
}

.potential__customer__wrapper__mbl {
  .chart__wrapper {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    height: 100%;
    width: 100%;

    .chart {
      height: fit-content;
      width: max-content;

      svg {
        width: 100% !important;
      }
    }

    .labels {
      flex-direction: column;
      -webkit-flex-direction: column;
      display: flex;
      display: -webkit-flex;
      align-items: start;
      -webkit-align-items: start;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      height: 100%;
      width: max-content;
      margin-top: 20px;
      border-radius: 20px;
      // background-color: $white1;

      .labels__item {
        flex: 1;
        -webkit-flex: 1;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        margin-right: auto;
        padding: 20px 30px;
        width: 100%;
        justify-content: center;
        -webkit-justify-content: center;
        border-bottom: 1px solid #e1e6e8;

        p {
          font-size: 20px;
          font-weight: 500;
          color: $primary-pale2;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.chart_title {
  font-size: 30px;
}

.chart_tooltip {
  width: auto;
  height: auto;
  min-width: 300px;
  border-radius: 10px;
  box-shadow: 0 2px 13px 1px rgb(112, 112, 112, 0.25);
  color: gray;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;

  .tooltip_string {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    margin-bottom: 5px;

    .string_label {
      font-size: 15px;
    }

    .string_value {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .tooltip_string_total {
    margin-bottom: 10px;

    .string_label {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .tooltip_title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
