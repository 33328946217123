@import "../../../../styles/index";

.potential__customer__wrapper {
  padding: 35px 50px;
  height: calc(100% - 75px);

  .header__text {
    text-align: center;
    margin-bottom: 10px;
  }

  .secondary__text {
    font-size: 15px;
    font-weight: 500;
    color: $primary-pale2;
    text-align: center;
    margin-bottom: 30px;
  }

  .data {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .chart_wrapper {
    display: flex;
    // justify-content: space-around;
    gap: 60px;

    .chart {
      margin-left: -20px;
    }
  }

  .content_section {
    background-color: white;
    width: 100%;
    margin-top: 15px;
  }

  .section {
    margin: 0px 20px;
  }

  .description {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    border-radius: 10px;
    background-color: $white1;
    padding: 28px 40px;
    height: fit-content;
    display: flex;
    gap: 16px;
    width: 100%;

    .description__title {
      width: 100%;
      text-align: center;
    }

    .section__description {
      border: 10px solid #beeef3;
      padding-left: 24px;
      border-width: 0px 0px 0px 11px;

      .section__description__title {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;

        p {
          font-size: 16px;
          font-weight: $font-weight-normal;
          color: $gray;
        }
      }

      .section__description__body {
        p {
          margin-top: 10px;
          font-size: 14px;
          font-weight: $font-weight-normal;
          color: $black;
          line-height: 12px;
        }
      }
    }
  }

  .labels {
    flex-direction: column;
    -webkit-flex-direction: column;
    display: flex;
    display: -webkit-flex;
    height: 100%;
    width: max-content;
    margin-top: 20px;

    .labels__item {
      flex: 1;
      -webkit-flex: 1;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      align-items: center;
      -webkit-align-items: center;
      margin-right: auto;
      padding: 20px 0px;
      width: 150px;
      border-bottom: 1px solid #e1e6e8;

      p {
        font-size: 14px;
        font-weight: 500;
        color: $primary-pale2;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .potential__customer__description {
    font-size: 20px;
    font-weight: $font-weight-middle;
    text-align: center;
    color: $primary-pale2;
  }

  .chart_title {
    font-size: 30px;
  }

  .chart_tooltip {
    width: auto;
    height: auto;
    min-width: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 13px 1px rgb(112, 112, 112, 0.25);
    color: gray;
    background-color: white;
    padding: 10px 20px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;

    .tooltip_string {
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      margin-bottom: 5px;

      .string_label {
        font-size: 15px;
      }

      .string_value {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .tooltip_string_total {
      margin-bottom: 10px;

      .string_label {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .tooltip_title {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}
