@import "../../../../../../styles/index";

.health__wrapper {
  .first__row {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;

    .title {
      font-size: 2rem;
      font-weight: $font-weight-middle;
      // color: $white;
    }

    .icon__wrapper {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      border-radius: 5px;
      padding: 2px 4px;
      width: 30px;
      height: 30px;
      color: $primary-pale1;
      background-color: $white;

      img {
        max-width: 100%;
        object-fit: none;
      }
    }
  }

  .content {
    .column__header {
      font-size: 1.2rem;
      font-weight: $font-weight-bold;
      margin-bottom: 20px;
    }

    .column__list {
      margin-left: 20px;
      margin-top: 30px;
      display: grid;
      display: -webkit-grid;
      grid-template-columns: repeat(2, 1fr);
      -webkit-grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 10px;

      @media (max-width: 1100px) {
        grid-template-columns: 1fr;
        -webkit-grid-template-columns: 1fr;
        grid-row-gap: 5px;
      }

      .list__item {
        font-size: 1.2rem;
        font-weight: $font-weight-normal;
        line-height: 20px;
      }

      b {
        font-size: 1.2rem;
      }
    }
  }
}
