@import "../../../../styles";

tspan {
  font-weight: 400 !important;
}

// .legend-item-0 {
//   path {
//     fill: $area-charts-curve;
//   }
// }

// .legend-item-1 {
//   path {
//     fill: $weak-bar;
//   }
// }

.bar {
  tspan {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .recharts-tooltip-cursor {
    fill: $primary-pale3 !important;
  }

  .strong-bar {
    fill: $area-charts-curve;
  }

  .weak-bar {
    fill: $weak-bar;
  }
}

// .recharts-label-list .recharts-text tspan {
//   font-size: 14px;
//   font-weight: 600 !important;
// }
